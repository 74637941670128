@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

* {
  margin: 0;
  padding: 0;
}

:root {
  --primaryClr: #3433c9;
  --secondaryClr: #292d2e;
  --secondaryClr1: #3a4142;
  --secondaryClr2: #1e2121;
  --headClr: #292d2e;
  --bodyClr: #232626;
  --whiteClr: #ffffff;
  --blackClr: #272727;
  --lightClr: rgba(255, 255, 255, 0.6);
  --oddsBack: #72bbef;
  --oddsLay: #faa8ba;
  --siteRedClr: #d9534f;
  --siteGreenClr: #27ae60;
  --gradientClr: linear-gradient(
    90deg,
    rgba(9, 9, 33, 1) 0%,
    rgba(52, 51, 201, 1) 100%
  );
}
.light-mode:root {
  --primaryClr: #ffb80c;
  --secondaryClr: #999;
  --headClr: rgba(0, 0, 0, 0.175);
  --bodyClr: #ddd;
  --whiteClr: #000000;
  --blackClr: #ffffff;
}

body {
  background: var(--bodyClr);
  color: var(--whiteClr);
  font-size: 14px;
  // font-family: "Roboto", sans-serif;
  font-family: "Poppins", sans-serif;
}
.container-fluid {
  @media screen and (min-width: 1320px) {
    width: 90%;
    max-width: 100%;
  }
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  transition: all 0.3s;
  &:focus,
  &:focus-visible {
    outline: none;
  }
}
.btn-primary {
  border-color: var(--primaryClr);
  background: var(--gradientClr);
  color: var(--whiteClr);
  height: 35px;
  font-size: 14px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 0.5rem;
  padding: 6px 8px;
  box-shadow: rgb(14 14 52 / 51%) 0px 0px 12px,
    rgb(51 50 197 / 49%) 0px -2px inset;
  &:hover,
  &:focus {
    filter: brightness(1.05);
  }
}
.btn-secondary {
  color: var(--whiteClr);
  border-color: var(--primaryClr);
  height: 40px;
  background: transparent;
  &:hover {
    background: linear-gradient(
      90deg,
      rgba(225, 177, 69, 1) 0%,
      rgba(202, 143, 5, 1) 50%,
      rgba(234, 187, 86, 1) 100%
    );
    border-color: var(--primaryClr) !important;
  }
}
.btn-outline-primary {
  border-color: var(--primaryClr);
  &:hover,
  &:focus {
    background: transparent !important;
    color: #000000 !important;
    opacity: 0.9;
    border-color: var(--primaryClr) !important;
  }
}
.btn-outline-secondary {
  font-size: 11px;
  border-radius: 0.4rem;
  padding: 6px 8px;
}
.back-link,
.back_btn {
  color: var(--primaryClr);
  font-size: 12px !important;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.65rem;
  border: 1px solid var(--primaryClr);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s;
  &:hover,
  &:focus {
    background: var(--primaryClr);
    color: var(--whiteClr);
  }
}
img {
  width: 100%;
  height: auto;
}
.all-input {
  position: relative;
}
input,
input.form-control {
  border-radius: 0;
  border-color: var(--primaryClr);
  font-size: 14px;
  font-weight: 300;
  transition: 0.4s;
  position: relative;
  &:focus {
    box-shadow: none !important;
  }
}
input.form-control ~ .focus-border::after,
input.form-control ~ .focus-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;

  background-color: #fb9255;
  transition: 0.3s;
}

input.form-control ~ .focus-border::after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}
input.form-control ~ .focus-border i::after,
input.form-control ~ .focus-border i::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #fb9255;
  transition: 0.4s;
}
input.form-control ~ .focus-border i::after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

input.form-control:focus ~ .focus-border i::after,
input.form-control:focus ~ .focus-border i::before {
  height: 100%;
  transition: 0.4s;
}
input.form-control:focus ~ .focus-border::after,
input.form-control:focus ~ .focus-border::before {
  width: 100%;
  transition: 0.3s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--secondaryClr2) inset !important;
  -webkit-text-fill-color: var(--whiteClr) !important;
}

body {
  .theme-btn {
    display: none;
    .theme-icon {
      width: 25px;
      height: 25px;
      border: 1px solid var(--whiteClr);
      border-radius: 50%;
      text-align: center;
      line-height: 13px;
      padding: 4px;
      .dark_theme {
        display: none;
      }
    }
  }
  &.light-mode {
    .theme-btn {
      .theme-icon {
        border-color: var(--whiteClr);
        .dark_theme {
          display: block;
        }
        .light_theme {
          display: none;
        }
      }
    }
  }
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  background: var(--headClr);
  width: 100%;
  z-index: 999;
  @media (max-width: 767.98px) {
    padding: 10px 0;
  }
  &.beforeheader {
    @media (max-width: 767.98px) {
      padding: 9px 0;
      background-color: transparent;
      // animation: bgTransition 1s forwards paused;
      // background-image: linear-gradient(to bottom, transparent, #232626 70%);
      &.scrolled {
        background-color: var(--headClr);
      }
    }
  }
  .middle-part {
    .navbar {
      .navbar-collapse {
        background-color: transparent !important;
      }
    }
  }
  .leftbar_toggle {
    .btn {
      background: transparent;
      border: 0;
      padding: 0;
      width: 26px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 575.98px) {
        width: 22px;
        height: 22px;
      }
      svg {
        width: 15px;
        height: 15px;
        display: block;
        fill: var(--whiteClr);
      }
      &:hover,
      &:focus {
        background: transparent !important;
      }
    }
  }
  .logo {
    max-width: 70px;
    img {
      max-height: 55px;
    }
    @media (max-width: 767.98px) {
      margin-left: 8px;
    }
    a {
      display: block;
    }
  }
  .navbar {
    padding: 0;
    .navbar-collapse {
      border-radius: 22px;
      .navbar-nav a {
        color: var(--whiteClr);
        padding: 4px 8px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        &.exchange-nav div {
          display: flex;
          margin-right: 5px;
          -webkit-animation: sports-book-bouncing 3s linear infinite alternate;
          animation: sports-book-bouncing 3s linear infinite alternate;
          svg,
          img {
            -webkit-animation: sports-book-rotate 3s linear infinite alternate;
            animation: sports-book-rotate 3s linear infinite alternate;
            margin-right: 0px;
          }
        }
        img {
          width: 22px;
          height: 22px;
          margin-right: 5px;
          filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.85));
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .headerRight {
    display: flex;
    align-items: center;
    fill: var(--whiteClr);
    .BalanceDropdown {
      border: 2px solid #3a4142;
      padding: 0.5rem;
      border-radius: 0.75rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 16.5rem;
      margin-right: 10px;
    }
    .BonuSection {
      li {
        a {
          background: var(--secondaryClr1);
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.5rem;
          height: 2.5rem;
          cursor: pointer;
          padding: 5px 10px;
          border: 1px solid var(--blackClr);
          border-radius: 0.5rem;
          margin-right: 10px;
          img {
            width: 20px;
          }
        }
      }
    }
    .MenuDropdown {
      .dropdown-toggle {
        border: 2px solid #3a4142 !important;
        border-radius: 50%;
        width: 42px;
        height: 42px;
        img {
          width: 35px;
          height: 35px;
        }
        &::after {
          display: none;
        }
      }
      .dropdown-menu {
        background-color: var(--secondaryClr);
        border-radius: 0.5rem;
        .dropdown-item {
          justify-content: start !important;
          color: var(--whiteClr);
          padding: 10px !important;
          span {
            color: var(--whiteClr) !important;
            font-weight: 500 !important;
            font-size: 14px !important;
          }
          img {
            width: 24px;
            height: 24px;
            margin-right: 12px;
          }
        }
      }
    }

    ul.chatnlanguage {
      display: flex;
      li {
        a {
          background: var(--secondaryClr1);
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.5rem;
          height: 2.5rem;
          cursor: pointer;
          padding: 5px 10px;
          margin-right: 10px;
          border: 1px solid var(--blackClr);
          border-radius: 0.5rem;
          img {
            width: 22px;
          }
        }
        &:last-child {
          a {
            margin: 0;
          }
        }
      }
    }

    @media (min-width: 768px) {
      padding: 8px 0;
    }
    a.deposit {
      background: var(--gradientClr);
      color: var(--whiteClr);
      font-weight: 600;
      width: 100%;
      text-align: center;
      display: block;
      margin: 0;
      border-radius: 5px;
      padding: 5px 10px;
    }
    .path-stroke {
      stroke: var(--whiteClr);
    }
    .dropdown {
      .btn {
        background: transparent;
        padding: 0;
        border: 0;
      }
      .dropdown-menu {
        min-width: 250px;
        .dropdown-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 10px;
          h6 {
            margin-bottom: 0;
          }
          span {
            color: #ff5513;
            font-size: 12px;
            font-weight: 600;
          }
          &.deposit {
            background: var(--gradientClr);
            color: var(--whiteClr);
            font-weight: 600;
            width: 90%;
            text-align: center;
            display: block;
            border-radius: 5px;
            margin: 9px auto 0;
            font-size: 1rem;
            height: auto !important;
            &:hover {
              background: var(--gradientClr);
            }
          }
          &:hover,
          &:focus {
            background: transparent;
            color: #212529;
          }
        }
      }
    }
    .funds {
      ul {
        li {
          a {
            color: var(--whiteClr);
            span {
              padding-left: 5px;
            }
            &.deposit {
              background: var(--siteGreenClr);
              font-weight: 600;
              padding: 5px 8px;
              border-radius: 3px;
            }
          }
        }
      }
    }
    .social {
      display: flex;
      align-items: center;
      margin-right: 5px;
      li {
        margin: 0 5px;
        position: relative;
        a {
          display: flex;
          background: var(--secondaryClr2);
          padding: 5px;
          border-radius: 5px;
          width: 35px;
          height: 35px;
          align-items: center;
          justify-content: center;
        }
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .menu-btn {
      padding: 0;
      background: none;
      border: none;
      &:hover,
      &:focus {
        background: none !important;
        border-color: transparent !important;
      }
      svg {
        width: 20px;
        height: auto;
        margin-left: 5px;
        fill: var(--primaryClr);
      }
    }
    .btn_signin {
      color: var(--whiteClr);
      background: transparent;
      border: 2px solid rgb(232 232 232);
      width: 85px;
      height: 40px;
      font-size: 14px;
      box-shadow: none;
      @media (max-width: 575.98px) {
        width: 70px;
      }
      &:hover {
        background: var(--gradientClr);
        color: var(--whiteClr);
      }
    }
    .btn_signup {
      color: var(--whiteClr);
      background: var(--gradientClr);
      border: 0;
      width: 85px;
      height: 40px;
      font-size: 14px;
      box-shadow: rgb(51, 50, 198) 0px 0px 12px, rgb(52 51 181) 0px -2px inset;
      // @media (max-width: 575.98px) {
      //   width: 90px;
      //   background-image: linear-gradient(90deg, #302aee, #7e7ef3);
      //   box-shadow: 0 0 12px rgb(27 28 106 / 30%), 0 -2px #1b19b3;
      // }.
    }
  }
  .bottom_head {
    background: var(--secondaryClr2);
    display: flex;
    justify-content: center;
    box-shadow: 1px 5px 12px rgb(0 0 0 / 43%);
  }
}

.logo,
.footer-logo {
  img {
    &.light-theme {
      display: none;
    }
  }
}
.light-mode {
  .logo,
  .footer-logo {
    img {
      &.light-theme {
        display: block;
      }
      &.dark-theme {
        display: none;
      }
    }
  }
}
.light-mode {
  .offcanvas {
    .offcanvas-body {
      .social_links {
        li {
          svg {
            filter: brightness(0);
          }
        }
      }
    }
  }
}

.back-link {
  font-size: 10px;
  color: var(--primaryClr);
  text-transform: uppercase;
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
  }
}
.offcanvas {
  border: none !important;
  z-index: 999999999;
}
.offcanvas-header {
  background: var(--blackClr);
  color: var(--whiteClr);
  .profile-img {
    width: 48px;
    height: 48px;
    background: var(--bodyClr);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    fill: var(--whiteClr);
  }
  .user-info {
    color: var(--whiteClr);
    font-size: 14px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      font-size: 16px;
      font-weight: bold;
    }
    a {
      color: var(--whiteClr);
    }
  }
  .btn-close {
    opacity: 1;
  }
}
.offcanvas-body {
  background: var(--bodyClr);
  .balance {
    font-size: 16px;
    color: var(--whiteClr);
    display: flex;
    align-items: center;
    svg {
      margin-right: 14px;
      fill: var(--primaryClr);
      stroke: var(--primaryClr);
    }
  }
  .balance-amount {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    color: var(--whiteClr);
  }
  .btn {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    height: 40px;
  }
  .top_sec {
    background: var(--secondaryClr2);
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    .col-6 {
      &:first-child,
      &:nth-child(5),
      &:nth-child(3) {
        padding-left: 10px;
        padding-right: 5px;
      }
      &:nth-child(2),
      &:nth-child(4),
      &:last-child {
        padding-right: 10px;
        padding-left: 5px;
      }
    }
  }
  .menu-items {
    margin: 0 -1rem;
    li {
      position: relative;
      // border-bottom: 1px solid var(--blackClr);
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        box-sizing: content-box;
        height: 1px;
        background-color: #000000;
        box-shadow: 0px 0.35px 0px 0px #313131;
        margin-bottom: 1px;
      }
      a {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        font-size: 14px;
        color: var(--whiteClr);
        &:hover,
        &:focus {
          background: var(--secondaryClr2);
        }
        img,
        svg {
          margin-right: 20px;
          width: 22px;
          height: auto;
        }
      }
    }
  }
  ul.social_links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    li {
      margin: 0 10px;
      position: relative;
      svg {
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        cursor: pointer;
      }
      .notification-count {
        width: 12px;
        height: 12px;
        background: var(--siteRedClr);
        color: var(--whiteClr);
        border-radius: 50%;
        position: absolute;
        right: -2px;
        top: -2px;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.form-steps {
  text-align: center;
}
body.leftbat-opened {
  .main,
  .live_casino_page {
    > .d-xl-none {
      width: 100%;
      height: calc(100vh - 52px);
      top: 70px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      z-index: 99999;
      @media (max-width: 767.98px) {
        top: 52px;
      }
    }
  }
  .header.aftrlgn {
    + .d-xl-none {
      width: 100%;
      height: calc(100vh - 52px);
      top: 70px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      z-index: 99999;
      @media (max-width: 767.98px) {
        top: 52px;
      }
    }
  }
  .leftbarSec {
    transform: translateX(0);
    ul {
      li {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        ul {
          background: var(--bodyClr);
          padding: 0px 10px;
          border-radius: 4px;
          li {
            position: relative;
            margin-bottom: 0;
            &:hover {
              background: linear-gradient(
                90deg,
                rgb(9, 9, 33) 0%,
                rgb(52, 51, 201) 100%
              );
            }
            &.mainOption {
              div {
                background: linear-gradient(
                  90deg,
                  rgba(9, 9, 33, 1) 0%,
                  rgba(52, 51, 201, 1) 100%
                );
                padding-left: 4px;
                .img-box {
                  border-radius: 50%;
                  img {
                    width: 33px !important;
                    height: 33px !important;
                  }
                }
                span {
                  img {
                    width: 32px;
                    margin-left: 6px;
                  }
                }
              }
            }
            &::after {
              content: "";
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              box-sizing: content-box;
              height: 1px;
              background-color: #000000;
              box-shadow: 0px 0.35px 0px 0px #717171;
              margin-bottom: 1px;
            }
            &:last-child {
              &::after {
                content: none;
              }
            }
          }
        }
      }
    }
  }
}
.mt--14 {
  .leftbarSec {
    margin-top: 0;
  }
}
.leftbarSec {
  background: var(--blackClr);
  width: 200px;
  height: calc(100vh - 85px);
  top: 85px;
  overflow-y: auto;
  padding: 10px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  @media only screen and (max-width: 1199.98px) {
    position: fixed;
    transform: translateX(-100%);
    transition: all 0.3s;
    z-index: 999;
  }
  @media only screen and (max-width: 767.98px) {
    top: 55px;
    width: 70%;
    height: calc(100vh - 55px);
    z-index: 99999999;
  }
  .games_link {
    h6 {
      color: var(--primaryClr);
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      padding: 5px 10px;
      border-bottom: 1px solid var(--primaryClr);
    }
    ul {
      li {
        cursor: pointer;
        > div {
          line-height: 36px;

          display: flex;
          align-items: center;
          padding: 3px;
          position: relative;
          justify-content: center;
          &:hover {
            background: #041823;
          }
          .animate-img {
            width: inherit !important;
          }
          .animate-img .spin-anim .img-pointer {
            position: absolute;
            top: -10px;
            left: 48%;
          }
          &.inplay {
            img {
              -webkit-animation: sports-book-bouncing 3s linear infinite
                alternate;
              animation: sports-book-bouncing 3s linear infinite alternate;
            }
          }
          &:hover img {
            -webkit-animation: icon-animation 1.5s infinite alternate;
            animation: icon-animation 1.5s infinite alternate;
            -webkit-animation: icon-animation1 1.5s infinite alternate;
            animation: icon-animation1 1.5s infinite alternate;
          }
          &.no-hover img.img-turntable {
            animation: rotate-e1vxjz6v 2s infinite linear;
          }
          &.no-hover img.img-pointer {
            animation: inherit !important;
            animation: inherit !important;
          }
          img {
            width: 20px;
            height: auto;
            filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.85));
          }
          span {
            color: var(--whiteClr);
            font-size: 13px;
            text-transform: capitalize;

            font-weight: 700;
          }
          .sport-event-count {
            background: var(--primaryClr);
            color: var(--blackClr);
            font-size: 12px;
            margin-left: auto;
            padding: 0.25em 0.4em;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
.main {
  @-webkit-keyframes icon-animation {
    to {
      -webkit-transform: scaleX(0.6);
      transform: scaleX(0.6);
    }
  }

  @keyframes icon-animation {
    to {
      -webkit-transform: scaleX(0.6);
      transform: scaleX(0.6);
    }
  }
  @-webkit-keyframes icon-animation1 {
    to {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
  }

  @keyframes icon-animation1 {
    to {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
  }
  .middleContentSec {
    width: 100%;
    @media only screen and (min-width: 1200px) {
      width: calc(100% - 600px);
      max-height: calc(100vh - 63px);
      overflow-y: auto;
      padding: 0 5px;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
        width: calc(100% - 500px);
      }
    }
  }
  .rightbarSec {
    background: var(--secondaryClr);
    width: 400px;
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
      width: 300px;
    }
    @media only screen and (max-width: 1199.98px) {
      display: none;
    }
    h1 {
      color: var(--primaryClr);
      font-size: 12px;
      font-weight: 600;
      line-height: 28px;
      padding: 5px 10px;
      border-bottom: 1px solid var(--primaryClr);
    }
    .VSlider {
      background-color: var(--headClr);
      border-radius: 10px;
      padding: 20px 10px;
      margin: 14px 0;
      .slick-slider {
        position: relative;
        .slide_box {
          width: 70%;
          margin: 0 auto;
          position: relative;
          img {
            border-radius: 5px;
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.45);
            border-radius: 5px;
          }
        }
        .slick-dots {
          top: 40%;
          bottom: auto;
          right: -40px;
          left: auto;
          transform: rotate(90deg);
          width: auto;
          li {
            width: 32px;
            height: 32px;
            transform: rotate(-90deg);
            button {
              border-radius: 50%;
              text-align: center;
              width: 32px;
              height: 32px;
              line-height: 32px;
              padding: 0;
              &::before {
                color: #ccc;
                font-size: 15px;
                font-family: Arial, Helvetica, sans-serif;
                text-shadow: 1px 1px 3px #555;
                width: 32px;
                height: 32px;
                line-height: 30px;
                opacity: 1;
                transition: all 0.4s;
              }
            }
            &:first-child {
              button {
                &::before {
                  content: "01";
                }
              }
            }
            &:nth-child(2) {
              button {
                &::before {
                  content: "02";
                }
              }
            }
            &:nth-child(3) {
              button {
                &::before {
                  content: "03";
                }
              }
            }
            &.slick-active {
              button {
                background: var(--primaryClr);
                &::before {
                  color: var(--whiteClr);
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
    .casino_games {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
      a {
        position: relative;
        img {
          border-radius: 5px;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(#3f3f4f 10%, #000 100%);
          color: var(--primaryClr);
          font-weight: 700;
          line-height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          opacity: 0;
          border-radius: 5px;
          .game_type {
            font-size: 10px;
            text-transform: uppercase;
            max-width: 90px;
            transition: all 0.4s;
          }
        }
        &:hover {
          .overlay {
            opacity: 0.8;
            .game_type {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    &.matched_bets {
      @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        display: block;
      }
      @media only screen and (max-width: 991.98px) {
        display: none;
      }
      .accordion {
        .accordion-item {
          background-color: var(--secondaryClr2);
          padding-bottom: 5px;
          margin-bottom: 10px;
          border: 0;
          border-radius: 5px;
          box-shadow: 0 0 10px rgb(0 0 0 / 60%);
          .accordion-header {
            .accordion-button {
              background: transparent;
              width: 100%;
              text-align: left;
              padding: 5px 10px;
              border: 0;
              // border-bottom: 1px solid var(--primaryClr);
              border-radius: 0;
              box-shadow: none;
              img {
                width: 20px;
                margin-right: 10px;
              }
              span {
                color: var(--primaryClr);
                font-size: 13px;
                font-weight: 600;
                line-height: 28px;
              }
              &:hover,
              &:focus {
                background: transparent !important;
              }
              &:after {
                content: none;
              }
            }
          }
          .accordion-body {
            color: var(--whiteClr);
            padding: 0;
            border: 0;
            .bets_box {
              .bet_info {
                .table {
                  background: var(--headClr);
                  margin-bottom: 0;
                  border-collapse: collapse;
                  overflow-x: auto;
                  border: 1px solid #222;
                  thead {
                    background: #222;
                    display: block;
                    tr {
                      th {
                        color: var(--whiteClr);
                        font-size: 12px;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 150px;
                        border: 0;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                      }
                    }
                  }
                  tbody {
                    display: block;
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 360px;
                    &::-webkit-scrollbar {
                      width: 6px;
                      height: 6px;
                    }
                    &::-webkit-scrollbar-track {
                      background: #222;
                    }
                    &::-webkit-scrollbar-thumb {
                      background: #333;
                      &:hover {
                        background: #444;
                      }
                    }
                    tr {
                      td {
                        color: var(--whiteClr);
                        font-size: 11px;
                        border-color: #333;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                        span {
                          color: var(--bodyClr);
                          width: 35px;
                          text-align: center;
                          display: inline-block;
                          border-radius: 4px;
                          &.lay {
                            background: #72bbef;
                          }
                          &.back {
                            background: #faa8ba;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .no_data {
              color: var(--whiteClr);
              font-size: 20px;
              font-weight: 600;
              width: 100%;
              text-align: center;
            }
          }
          &.live_tv {
            .accordion-body {
              background: var(--bodyClr);
              .live_tv_box {
                height: 175px;
              }
            }
          }
          &.bets {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .match_slider_sec {
    width: calc(100% - 50px);
    margin-left: 25px;
    .match_info_slider {
      .info_box {
        // background: var(--headClr);
        // background: linear-gradient(
        //   50deg,
        //   rgb(34 42 55) 0%,
        //   rgb(52 59 70) 50%,
        //   rgb(36 42 55) 100%
        // );
        background-image: url(./assets/images/cricket.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 128px;
        padding: 8px;
        margin: 0 5px;
        border-radius: 10px;
        .match {
          text-align: center;
          font-size: 10px;
          line-height: 12px;
          display: flex;
          justify-content: space-between;
          .tournament {
            color: #aa9d9d;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            font-weight: 600;
            &.series-name {
              color: var(--primaryClr);
            }
          }
          .time {
            color: var(--whiteClr);
            font-weight: 500;
          }
        }
        .participants {
          display: flex;
          position: relative;
          margin: 4px;
          color: var(--whiteClr);
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          .time {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            span {
              color: #9d9da1;
              font-weight: 100;
              font-size: 14px;
              line-height: 18px;
            }
            span.match-time {
              color: var(--whiteClr);
              font-weight: bold;
            }
          }
          .item {
            flex: 1;
            overflow: hidden;
            font-size: 10px;
            text-align: center;
            .flag {
              width: 32px;
              height: 32px;
              line-height: 28px;
              margin: 0 auto;
              border-radius: 50%;
            }
            .name {
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              margin-top: 8px;
              font-weight: 600;
            }
          }
        }
        .market {
          display: flex;
          gap: 4px;
          .outcome {
            background: #373d57;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-radius: 4px;
            .name {
              color: #abb0ba;
            }
            .odds {
              color: #e7cf90;
            }
          }
        }
      }
      .slick-arrow {
        background: #222430;
        height: 100%;
        &.slick-prev {
          border-radius: 8px 0 0 8px;
        }
        &.slick-next {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }
  .popular_game_sec {
    background: transparent;
    padding: 2rem;
    border-radius: 1.5rem;
    @media screen and (max-width: 767.98px) {
      padding: 0;
      margin-top: 35px;
    }
    .head-popular {
      font-weight: 600;
      font-size: 1.25rem;
    }
    .game_box {
      margin: 5px;
      a {
        img {
          border-radius: 0.75rem;
        }
      }
    }
  }
  .sports_banner_sec {
    // background-color: #181818;
    // padding: 0.5rem;
    // margin-bottom: 2.5rem;
    // border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    @media screen and (max-width: 767.98px) {
      padding: 0rem;
      background-color: transparent;
    }
    .img_box {
      img {
        border-radius: 1.5rem;
      }
    }
  }
  .casino_sec {
    background-color: var(--blackClr);
    padding: 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;

    @media screen and (max-width: 1199.98px) {
      padding: 1rem;
    }
    .heading {
      align-items: self-start;
      min-width: 20%;
      background: transparent !important;
      padding: 0;
      @media screen and (max-width: 767.98px) {
        margin-bottom: 1rem !important;
      }
      h5 {
        font-weight: 600;
      }
      h6 {
        font-weight: 500;
        font-size: 15px;
      }
      .btn {
        color: var(--whiteClr);
        font-size: 16px;
        // border-color: #1d803a;
        width: 90%;
        min-width: 170px;
        height: 40px;
        @media screen and (max-width: 1199.98px) {
          width: 100%;
        }
      }
    }
    .games {
      overflow: hidden;
      a {
        img {
          max-width: 95%;
          border-radius: 0.75rem;
        }
      }
    }
  }

  .TopCategories {
    margin: 0 0px 30px;
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;
      margin: 0;
      padding: 0;
      background: var(--secondaryClr);
      list-style: none;
      overflow-x: auto;
      border: 0px;
      margin-bottom: 20px;
      .nav-item {
        button,
        .anav-link {
          background-size: 30px 30px !important;
          background-position: 27px 10px !important;
          background-repeat: no-repeat !important;
          color: var(--whiteClr);
          font-size: 14px;
          width: 92px;
          height: 100%;
          padding: 46px 5px 5px;
          border: 0;
          border-radius: 0;
          &.active {
            background: var(--bodyClr);
          }
          &#uncontrolled-tab-example-tab-Roulette {
            background-image: url("https://gbet567.com/images/Roulette_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Baccarat {
            background-image: url("https://gbet567.com/images/Baccarat_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Andar\ Bahar {
            background-image: url("https://gbet567.com/images/Andar_bahar_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Poker {
            background-image: url("https://gbet567.com/images/Poker_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Blackjack {
            background-image: url("https://gbet567.com/images/Blackjack_icons.png");
          }
          &#uncontrolled-tab-example-tab-TeenPatti {
            background-image: url("https://gbet567.com/images/Teenpatti.png");
          }
          &#uncontrolled-tab-example-tab-32Card {
            background-image: url("https://gbet567.com/images/casino.png");
          }
          &#uncontrolled-tab-example-tab-Seven {
            background-image: url("https://gbet567.com/images/seven.png");
          }
          &#uncontrolled-tab-example-tab-Dices {
            background-image: url("https://gbet567.com/images/dices.png");
          }
          &#uncontrolled-tab-example-tab-Sports {
            background-image: url("../public/images/sportsss.png");
          }
        }
      }
    }
  }

  .sectionTitle {
    font-size: 18px;
    font-weight: normal;
    color: var(--whiteClr);
    position: relative;
    display: inline-block;
    margin: 0px 0 10px;
    padding-bottom: 5px;
    &:after {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
      height: 1px;
      background: var(--primaryClr);
    }
  }
  .innerBanner {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    .link {
      width: 100%;
      background: var(--primaryClr);
      font-size: 11px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: var(--whiteClr);
      text-transform: uppercase;
      padding: 8px;
    }
  }
  .payment-info {
    background: var(--secondaryClr2);
    margin: 25px 0;
    padding: 22px 1rem;
    color: var(--whiteClr);
    .contact-social {
      h3 {
        margin-bottom: 15px;
      }
      ul {
        li {
          margin-bottom: 15px;
          a {
            background: var(--bodyClr);
            color: var(--whiteClr);
            font-size: 16px;
            width: 100%;
            max-width: 250px;
            display: flex;
            align-items: center;
            border: 2px solid var(--bodyClr);
            border-radius: 0.35rem;
            transition: all 0.3s ease-in;
            &:hover {
              transform: scale(1.1);
              box-shadow: 1px 5px 12px rgba(0, 0, 0, 0.43);
            }
            .img-box {
              background: var(--headClr);
              text-align: center;
              height: 40px;
              width: 40px;
              line-height: 40px;
              border-radius: 0.35rem 0 0 0.35rem;
              img {
                width: 25px;
                height: auto;
              }
            }
            h5 {
              color: var(--whiteClr);
              margin-bottom: 0;
              padding: 0 15px;
            }
            p {
              margin: 0;
            }
          }
        }
      }
    }
    .contact_img {
      animation: moving 3s linear infinite;
      @keyframes moving {
        0% {
          left: 0;
        }
        50% {
          left: 0;
          -webkit-transform: translateY(15px);
          transform: translateY(15px);
        }
      }
    }
    &.contact-pay-info {
      padding: 90px 1rem 0;
    }
    .contact-img img {
      width: 380px;
      animation-name: bounce-1;
      animation-timing-function: linear;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
    .balance {
      font-size: 16px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 14px;
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        opacity: 0.6;
      }
    }
    .balance-amount {
      text-align: right;
      font-weight: bold;
      font-size: 16px;
    }
    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      &.btn-outline-secondary {
        border-color: var(--whiteClr);
        color: var(--whiteClr);
      }
    }
  }
  .account-statement {
    input {
      z-index: 9 !important;
      &::placeholder {
        color: #878787;
      }
    }
    button.calender-icon {
      svg {
        opacity: 0;
        @media only screen and (max-width: 767px) {
          opacity: 1;
        }
      }
    }
    .search-icon {
      button {
        svg {
          display: block;
        }
      }
    }
    button {
      position: absolute;
      right: 0;
      width: 40px;
      height: 33px;
    }
    h3 {
      border-bottom: 1px solid #878787;
      font-size: 16px;
      font-weight: 300;
      margin: 12px 0 22px;
      padding: 0 1rem 12px 1rem;
    }
    .btn-primary {
      border-radius: 0;
      padding: 6px 10px;
    }
    ul {
      border-top: 1px solid #878787;
      li {
        padding: 22px 1rem;
        border-bottom: 1px solid #878787;
        .h4 {
          font-size: 14px;
          margin-bottom: 0;
        }
        .h5 {
          margin-bottom: 0;
          font-size: 12px;
          letter-spacing: 0.86px;
        }
        .details {
          font-size: 12px;
          font-weight: 300;
        }
        .statue {
          font-size: 10px;
          font-weight: 300;
          letter-spacing: 0.7px;
        }
        small {
          margin-top: 10px;
          font-size: 10px;
          font-weight: 300;
          display: block;
        }
      }
    }
    table {
      thead {
        background: var(--secondaryClr2);
        tr {
          th {
            background: var(--secondaryClr2);
            color: var(--whiteClr);
            white-space: nowrap;
            border-bottom: 0;
          }
        }
      }
      tbody {
        background: var(--blackClr);
        color: var(--whiteClr);
        tr {
          td {
            background: var(--blackClr);
            color: var(--whiteClr);
            white-space: nowrap;
            border-bottom-color: #555;
          }
          &:last-child {
            td {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
  .profile-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 30px;
    img {
      max-width: 120px;
      width: 120px;
      height: 120px;
      border: 3px solid var(--primaryClr);
      padding: 6px;
    }
    h5 {
      color: var(--whiteClr);
      margin: 15px 0;
    }
    p {
      color: var(--whiteClr);
      font-size: 14px;
      font-weight: 300;
      text-align: center;
      margin-left: 30px;
      margin-bottom: 7px;
      opacity: 0.7;
    }
    h6 {
      font-size: 16px;
      margin-left: 30px;
      margin-bottom: 0;
    }
  }
  .edit-btn {
    background: var(--whiteClr);
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    fill: var(--whiteClr);
    padding: 4px;
    top: 58px;
    right: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile-info {
    border-radius: 5px;
    margin: 0 auto;
    max-width: 500px;
    padding: 25px 15px;
    border: 2px solid var(--blackClr);
    ul {
      li {
        &.lable {
          color: var(--whiteClr);
          padding-bottom: 5px;
          opacity: 0.8;
        }
        &.info {
          background: var(--secondaryClr2);
          border-radius: 5px;
          color: var(--whiteClr);
          height: 40px;
          line-height: 40px;
          padding: 0 10px;
          position: relative;
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
        .editBtn {
          position: absolute;
          right: 0;
          top: -1px;
          .btn-primary {
            background: var(--gradientClr);
            border: 0;
            border-radius: 0 5px 5px 0;
            height: 40px;
            transition: all 0.3s;
            width: 40px;
            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }
}

.listings {
  .filter {
    margin-bottom: 20px;

    .dropdown-toggle {
      background: var(--primaryClr) !important;
      border: none !important;
      border-radius: 0;
      padding: 5px 15px;
    }
    .btn-outline-primary {
      width: 100%;
      padding: 5px;
      background: transparent;
      border-radius: 0 !important;
    }
    .dropdown-menu {
      border-radius: 0;
      border: 1px solid var(--whiteClr);
      width: 100% !important;
      white-space: pre-wrap;
      min-width: auto;
      padding: 0 !important;
      margin-top: -3px;
      a {
        border-bottom: 1px solid var(--whiteClr);
        color: var(--headClr);
        font-size: 14px;
        opacity: 0.8;
        padding: 13px 18px;
        &:last-child {
          border: none;
        }
      }
    }
  }
  .like-btn {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 9px;
    border-radius: 0 0 7px 0;
    svg {
      fill: var(--whiteClr);
    }
  }
}

.steps-canvas {
  .offcanvas-header {
    background: var(--blackClr);
    .offcanvas-title {
      font-size: 20px;
      font-weight: bold;
      color: var(--whiteClr);
    }
  }
  .form-steps {
    padding: 55px 30px 0;
    .resendOTP {
      margin-right: 15px;
      @media only screen and (max-width: 767px) {
        margin-right: 15px;
        margin-bottom: 15px;
      }
    }
    .h2 {
      font-size: 28px;
      font-weight: 500;
      color: var(--whiteClr);
    }
    p {
      font-size: 12px;
      font-weight: 300;
      color: var(--whiteClr);
    }
    input {
      background: var(--headClr);
      border-color: #878787;
      padding: 14px 20px;
      color: var(--whiteClr);
      &::placeholder {
        color: #878787;
      }
    }
    .icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      svg {
        fill: var(--whiteClr);
        width: 20px;
        height: 20px;
        path,
        circle {
          stroke: var(--whiteClr);
        }
      }
    }
    .btn {
      height: 40px;
      padding: 6px 30px;
    }

    .form-link {
      font-size: 14px;
      color: var(--whiteClr);
      &:hover {
        opacity: 0.7;
      }
    }
    .sign-up {
      color: var(--lightClr);
      font-size: 14px;
      font-weight: 500;
      a {
        font-size: 16px;
        color: var(--primaryClr);
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.tabs-area {
  margin-top: 10px;
  ul {
    flex-wrap: nowrap;
    li {
      width: 100%;
      &:not(:last-child) {
        border-right: 1px solid var(--whiteClr);
      }
      .nav-link {
        width: 100%;
        background: var(--headClr);
        color: rgba(255, 255, 255, 0.6);
        border-radius: 0;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 17px 10px;
        border: none;
        &.active {
          background: #f3f3f3 !important;
          color: #26324b !important;
        }
      }
    }
  }
  .tab-content {
    .bank-details {
      margin-top: 22px;
      background: var(--whiteClr);
      box-shadow: 5px 5px 10px #0000001a;
      border-radius: 10px;
      overflow: hidden;
      .top-area {
        background: var(--whiteClr);
        width: 100%;
        font-size: 14px;
        padding: 20px;
      }
      .bottom-area {
        padding: 20px;
        table {
          margin-bottom: 0;
          th {
            font-size: 18px;
            font-weight: 500;
            padding: 5px 0;
          }
          td {
            font-size: 14px;
            font-weight: 300;
            padding: 5px 0;
            width: 50%;
          }
        }
      }
    }
    form {
      padding: 55px 30px 0;
      text-align: center;
      input {
        background: var(--headClr);
        border-color: #4a5d6b;
        padding: 14px 20px;
        &.form-check-input {
          border-radius: 50px;
          width: 24px;
          height: 24px;
          border: 2px solid var(--primaryClr);
          padding: 0;
          background-color: var(--whiteClr);
          position: relative;
          &:checked:after {
            content: "";
            background: var(--headClr);
            border-radius: 50px;
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
          }
        }
      }
      .btn {
        padding: 9px 30px;
        font-size: 14px;
        text-transform: uppercase;
        min-width: 120px;
        margin-right: 10px;
        margin-bottom: 5px;
      }
      .radio-btns {
        display: flex;
        .form-check {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 300;
          .form-check-label {
            margin-left: 5px;
            margin-top: 3px;
          }
        }
      }
    }
    .card-holder {
      height: 115px;
      background: var(--whiteClr);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 5px 5px 10px #0000001a;
      img {
        max-height: 40px;
      }
    }
  }
  .h4 {
    font-size: 14px;
    font-weight: 500;
  }
  .sectionTitle {
    &::after {
      background: var(--headClr);
      width: 100%;
    }
  }
}

.transfer_info_page {
  .transfer_details {
    background: url("./assets/images/deposit1.jpg") var(--secondaryClr);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    padding: 10px;
    border: 1px solid #333;
    border-radius: 10px;
    overflow: hidden;
    z-index: 9;
    form {
      .form-group {
        background: linear-gradient(to right, #10091d, #000d1b) !important;
        border: 1px solid #013f48;
        border-radius: 5px;
        padding-top: 7px;
        .form-label {
          color: rgba(255, 255, 255, 0.7);
          margin-bottom: 3px;
        }
        .form-control {
          padding: 10px 15px;
          border: 0;
          border-top: 1px solid rgb(1, 63, 72, 0.35);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
            0 1px 2px 0 rgba(0, 0, 0, 0.12),
            inset 0 1px rgba(255, 255, 255, 0.04);
        }
      }
    }
  }
  .deposit_img {
    img {
      animation: moving 3s infinite linear;
      @keyframes moving {
        0% {
          left: 0;
        }
        50% {
          left: 0;
          -webkit-transform: translateY(15px);
          transform: translateY(15px);
        }
        100% {
          left: 0;
        }
      }
    }
  }
}

.upi-list {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      margin: 0 7px;
      width: 50px;
      border: none;
    }
  }
}

.sports_page_banner {
  .slick-slider {
    img {
      border-radius: 5px;
      height: 210px;
      object-fit: cover;
    }
    .slick-dots {
      bottom: 8px;
      li {
        height: 3px;
        button {
          height: 3px;
          padding: 0;
          &:before {
            background: rgb(135 135 135 / 70%);
            color: transparent;
            width: 25px;
            height: 3px;
            opacity: 1;
          }
        }
        &.slick-active {
          button {
            &:before {
              background: var(--primaryClr);
            }
          }
        }
      }
    }
  }
}

.sports_page,
.custom-tabs {
  padding-top: 10px;
  .sports-name-listing {
    ul {
      flex-wrap: nowrap;
      padding: 10px;
      background: var(--secondaryClr);
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        a,
        div {
          color: var(--whiteClr);
          padding: 5px;
          display: block;
          cursor: pointer;
          &:hover,
          &.active {
            background: transparent;
            color: var(--primaryClr);
          }
        }
      }
    }
  }
  .nav {
    flex-wrap: nowrap;
    background: var(--secondaryClr1);
    // margin-bottom: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    .nav-item {
      margin: 0 5px;
      .nav-link {
        color: var(--whiteClr);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px;
        border: none;
        border-radius: 0;
        &:hover,
        &.active {
          background: transparent;
          color: var(--primaryClr);
        }
        p {
          margin: 0;
          top: -6px;
          right: -4px;
          width: 14px;
          height: 14px;
          background: #3e3d3d;
          box-shadow: 2px 5px 15px #1d1d1d;
          border-radius: 50%;
          display: flex;
          font-size: 9px;
          justify-content: center;
          align-items: center;
        }
        &:hover,
        &.active {
          background: transparent;
          color: var(--primaryClr);
        }
      }
    }
  }
  .tab-pane {
    .title {
      background: var(--headClr);
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      display: none;
      @media only screen and (max-width: 767.98px) {
        margin-bottom: 0;
        justify-content: center;
      }
      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        color: var(--whiteClr);
        margin-left: -1rem;
      }
    }
    .game-listing {
      background: var(--secondaryClr);
      border: 1px solid var(--headClr);
      border-radius: 0 0 0.5rem 0.5rem;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);

      .match-listing-head {
        display: none;
        ul {
          background-color: #ced5da;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li:first-child {
            width: calc(100% - 300px);
            display: block;
          }
          li {
            width: 48px;
            color: #000;
            padding: 5px 3px;
            font-size: 12px;
          }
        }
      }
      .match-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px;
        border-bottom: 1px solid rgba(255, 184, 12, 0.1);
        background: var(--secondaryClr);
        // height: 25px;
        &:last-child {
          border-bottom: 0;
          border-radius: 0 0 5px 5px;
        }
        .live-match {
          height: 8px;
          width: 8px;
          margin-right: 5px;
          background: #c4cfd6;
          border: 1px solid #366601;
          border-radius: 50%;
        }
        .live-match.active {
          background: #6aba12;
          border: 1px solid #366601;
        }
        .match-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: calc(100% - 315px);
          padding: 5px 0;
          .breadcrumb {
            font-size: 12px;
            display: flex;
            align-items: center;
            font-weight: 700;
            color: var(--whiteClr);
            cursor: pointer;
            .date_time_info {
              background: #36404d;
              width: 68px;
              height: 42px;
              overflow: hidden;
              margin-right: 10px;
              padding: 6px 1px;
              border-radius: 5px;
              position: relative;
              .date {
                color: var(--whiteClr);
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
              }
              &.in-play {
                background: var(--headClr);
                .date {
                  color: var(--primaryClr) !important;
                }
                span {
                  position: absolute;
                  background: linear-gradient(
                    to right,
                    #171618,
                    var(--primaryClr)
                  );
                  &:nth-child(1) {
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate1 2s linear infinite;
                    @keyframes animate1 {
                      0% {
                        transform: translateX(-100%);
                      }
                      100% {
                        transform: translateX(100%);
                      }
                    }
                  }
                  &:nth-child(2) {
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate2 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate2 {
                      0% {
                        transform: translateY(-100%);
                      }
                      100% {
                        transform: translateY(100%);
                      }
                    }
                  }
                  &:nth-child(3) {
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate3 2s linear infinite;
                    @keyframes animate3 {
                      0% {
                        transform: translateX(100%);
                      }
                      100% {
                        transform: translateX(-100%);
                      }
                    }
                  }
                  &:nth-child(4) {
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate4 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate4 {
                      0% {
                        transform: translateY(100%);
                      }
                      100% {
                        transform: translateY(-100%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .status {
          background: var(--oddsBack);
          color: var(--blackClr);
          display: flex;
          padding: 0 3px;
          font-size: 11px;
          font-style: italic;
          font-weight: 600;
          border-radius: 2px;
          align-items: center;
          justify-content: center;
        }
        .listing {
          display: flex;
          flex-wrap: nowrap;
          li.suspended {
            position: relative;
            display: flex;
            width: 100%;
            p {
              position: absolute;
              margin: 0;
              color: #b91616;
              -webkit-backdrop-filter: blur(10px);
              backdrop-filter: blur(10px);
              background: rgba(231 238 255 / 0.8);
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: bold;
              border-radius: 4px;
            }
          }
          li {
            margin-right: 5px;
            width: 45px;
            height: 30px;
            &:nth-child(2),
            &:nth-child(4) {
              margin-right: 10px;
            }
            &:last-child {
              margin-right: 0;
            }
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              padding: 0 2px;
              cursor: pointer;
              height: 30px;
              line-height: 30px;
              border-radius: 5px;
              span {
                color: var(--blackClr);
                font-size: 12px;
                font-weight: 700;
              }
              &.yes {
                background: var(--oddsBack);
              }
              &.no {
                background: var(--oddsLay);
              }
            }
          }
        }
      }
    }
    .top-row {
      display: flex;
      justify-content: space-between;
      background: var(--headClr);
      margin: 20px -1rem;
      padding: 0 1rem;
      .bet-btns {
        display: flex;
        li {
          background: var(--primaryClr);
          color: var(--whiteClr);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
    .game-points {
      ul {
        li {
          display: flex;
          border-bottom: 1px solid var(--secondaryClr);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;
          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;
            .time {
              color: var(--siteRedClr);
            }
          }
          .score {
            margin-left: auto;
          }
          .status-btns {
            margin-left: 5px;
            position: relative;
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;
              span {
                font-size: 12px;
              }
            }
            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteClr);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .game-top-area {
      background: var(--headClr);
      margin: 0 -1rem;
      padding: 10px 1rem;
      .date {
        text-align: center;
        color: var(--primaryClr);
        font-size: 12px;
        margin-bottom: 5px;
      }
      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .range {
        background: var(--primaryClr);
        font-size: 11px;
        color: var(--blackClr);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.sports_page {
  .tab-content {
    .tab-pane {
      max-height: calc(100vh - 120px);
      padding-bottom: 10px;
      overflow-y: auto;
    }
  }
}
.match_details_container {
  background: #101010;
  padding-top: 15px;
  .math_info {
    text-align: center;
    .match_type {
      background: linear-gradient(
        to right,
        #2b8b30 0,
        #3c8540 15%,
        #2b8b30 30%,
        #2b8b30 55%,
        #2b8b30 100%
      );
      color: var(--whiteClr);
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      text-shadow: 1px 1px 3px rgb(0 0 0 / 50%);
      word-spacing: 3px;
      width: fit-content;
      height: 34px;
      line-height: 34px;
      padding: 0 15px;
      margin: 0 auto;
      border-radius: 25px;
      position: relative;
      z-index: 1;
      animation: shine 3s linear infinite;
      @keyframes shine {
        0% {
          -webkit-box-shadow: 0 0 0 0 var(--primaryClr);
          box-shadow: 0 0 0 0 var(--primaryClr);
        }
        40% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        80% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        100% {
          -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
        }
      }
      &:after {
        position: absolute;
        content: "";
        top: 16px;
        left: -40%;
        background: var(--primaryClr);
        width: 180%;
        height: 2px;
        z-index: -1;
        @media screen and (max-width: 767px) {
          width: 110%;
          left: -5%;
        }
      }
    }
    .team_names {
      margin: 15px 0 10px;
      span {
        color: var(--primaryClr);
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        @media screen and (max-height: 575.98px) {
          font-size: 13px;
        }
        span {
          color: rgb(237, 33, 58);
          font-size: 12px;
          @media screen and (max-height: 575.98px) {
            font-size: 10px;
          }
        }
        &:last-child {
          span {
            display: none;
          }
        }
      }
    }
    .collapse_container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      .btn {
        background: linear-gradient(
          to right,
          #1186d3 0,
          #41aaf0 15%,
          #41aaf0 30%,
          #1186d3 55%,
          #1186d3 100%
        ) !important;
        border: 0;
        border-radius: 5px;
        padding: 4px 6px;
        height: auto;
        &:hover,
        &:focus {
          background: linear-gradient(
            to right,
            #1186d3 0,
            #41aaf0 15%,
            #41aaf0 30%,
            #1186d3 55%,
            #1186d3 100%
          ) !important;
        }
        img {
          width: 14px;
        }
      }
      .in_play {
        background: #111;
        color: #41aaf0;
        text-transform: uppercase;
        letter-spacing: 2px;
        overflow: hidden;
        font-weight: 700;
        margin: 0 15px;
        padding: 2px 6px;
        position: relative;
        .anim_line1 {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to right, #171618, #1186d3);
          animation: animate1 2s linear infinite;
        }
        .anim_line2 {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to bottom, #171618, #1186d3);
          animation: animate2 2s linear infinite;
          animation-delay: 1s;
        }
        .anim_line3 {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to left, #171618, #1186d3);
          animation: animate3 2s linear infinite;
        }
        .anim_line4 {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to top, #171618, #1186d3);
          animation: animate4 2s linear infinite;
          animation-delay: 1s;
        }
      }
    }
  }
  .sports-match-iframe {
    height: 100%;
    @media screen and (min-width: 1400px) {
      height: 390px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1399.98px) {
      height: 330px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199.98px) {
      height: 361px;
    }
    @media screen and (min-width: 992px) and (max-width: 1023.98px) {
      height: 311px;
    }
    @media screen and (min-width: 880px) and (max-width: 991.98px) {
      height: 391px;
    }
    @media screen and (min-width: 768px) and (max-width: 879.98px) {
      height: 361px;
    }
    @media screen and (min-width: 650px) and (max-width: 767.98px) {
      height: 321px;
    }
    @media screen and (min-width: 576px) and (max-width: 649.98px) {
      height: 291px;
    }
    @media screen and (min-width: 480px) and (max-width: 575.98px) {
      height: 381px;
    }
    @media screen and (min-width: 420px) and (max-width: 479.98px) {
      height: 350px;
    }
    @media screen and (min-width: 380px) and (max-width: 419.98px) {
      height: 330px;
    }
    @media screen and (min-width: 360px) and (max-width: 379.98px) {
      height: 310px;
    }
    @media screen and (min-width: 320px) and (max-width: 359.98px) {
      height: 290px;
    }
    @media screen and (max-width: 319.98px) {
      height: 280px;
    }
  }
}
.page-details {
  .game-points {
    padding-bottom: 0;
  }
  .top-row {
    display: flex;
    justify-content: space-between;
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    ul.bet-btns {
      display: flex;
      cursor: pointer;

      li {
        background: var(--primaryClr);
        color: var(--blackClr);
        margin: 4px 0px 4px 4px;
        text-transform: uppercase;
        font-size: 11px;
        padding: 4px 8px;
        border-radius: 2px;
      }
    }
  }
  .game-top-area {
    background: var(--secondaryClr1);
    margin-bottom: 0px;
    padding: 5px 1rem 5px 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .date {
      text-align: center;
      color: var(--whiteClr);
      font-size: 12px;
      margin-bottom: 5px;
      order: 2;
      margin-bottom: 0;
    }
    .title {
      background: transparent;
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 30px;
      color: var(--whiteClr);
      font-weight: bold;
    }
    .range {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      float: none;
      padding: 0 6px;
      margin: 7px 0;
      background: var(--primaryClr);
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .title {
    background: var(--headClr);
    padding: 5px 0rem 5px 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 767.98px) {
      background-color: #3b5160;
      margin-bottom: 0;
      margin-top: 0 !important;
      padding: 0 0.25rem 0 0.5rem;
    }
    span {
      font-size: 14px;
      padding: 6px 0;
      display: inline-block;
      color: var(--whiteClr);
    }
    .status-btns {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767.98px) {
        width: 136px;
      }
      .info-block {
        font-size: 13px;
        font-weight: 600;
        color: var(--blackClr);
        text-align: center;
        width: 68px;
        height: 26px;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2px 1px 1px;
        padding: 3px 2px;
        border-radius: 2px;
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
        @media screen and (max-width: 767.98px) {
          width: 50%;
        }
      }
    }
  }
  .game-points {
    border: 1px solid var(--headClr);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);
    padding-bottom: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    ul {
      li {
        display: flex;
        border-bottom: 1px solid var(--secondaryClr);
        padding: 1px 0 1px 0.75rem;
        align-items: center;
        .score {
          margin-left: auto;
          margin-right: 5px;
        }
        .team-name {
          font-size: 12px;
          font-weight: 700;
          line-height: normal;
          display: flex;
          flex-direction: column;
          .time {
            color: var(--siteRedClr);
          }
        }
        .status-btns {
          display: flex;
          align-items: center;
          position: relative;
          .points-status {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
            font-size: 12px;
            color: var(--primaryClr);
            text-align: center;
            border-radius: 5px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after {
              position: absolute;
              top: 7px;
              left: 5px;
              border: 1px solid var(--primaryClr);
              content: "";
              padding: 10px;
              width: calc(100% - 10px);
              height: 24px;
              border-radius: 5px;
            }
          }
          &.match_odds {
            .info-block {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(5),
              &:nth-child(6) {
                background: #e9e9e9;
              }
            }
          }
        }
        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 8px;
          line-height: normal;
          font-size: 10px;
          margin: 2px 1px 1px;
          padding: 5px 2px;
          width: 68px;
          text-align: center;
          color: var(--blackClr);
          cursor: pointer;
          border-radius: 4px;
          &.yes {
            background: var(--oddsBack);
          }
          &.no {
            background: var(--oddsLay);
          }
          span {
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.footer {
  background: var(--secondaryClr2);
  padding: 0 15px;
  @media (max-width: 991.98px) and (min-width: 576px) {
    padding-bottom: 60px;
  }
  @media (max-width: 575.98px) {
    padding: 0 0 60px;
  }
  .inner-content {
    background: var(--blackClr);
    padding: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem 1.5rem 0 0;
    @media (max-width: 991.98px) {
      padding: 1rem 1rem 5rem;
    }
  }
  .providers_sec {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    position: relative;
    border-bottom: 1px solid #333;
    &::before {
      content: "";
      position: absolute;
      height: 80%;
      width: 1px;
      background-color: #7e7e7e;
      left: 50%;
      top: 10%;
      transform: translate(-50% - -50%);
    }
    @media (max-width: 1199.98px) {
      padding: 1rem;
    }
    .heading {
      background-color: transparent !important;
      text-align: center;
      justify-content: center;
      h5 {
        font-size: 22px;
        font-weight: bold;
      }
      @media screen and (max-width: 1199.98px) {
        margin: 0 auto;
      }
      p {
        span {
          color: var(--primaryClr);
        }
      }
    }
    .providers_logo {
      // width: calc(100% - 300px);
      &.payment_logo {
        a {
          width: 80px !important;
        }
      }
      @media screen and (max-width: 1199.98px) {
        width: calc(100% - 0px);
        margin-bottom: 20px !important;
      }
      .logo_box {
        margin: 3px;
        text-align: center;
        img {
          max-width: 120px;
          padding: 3px;
          &.payment-height {
            max-width: 55px;
          }
        }
      }
    }
  }
  .providers_img {
    text-align: center;
    a {
      display: inline-block;
      padding: 0 18px;
      max-width: 130px;
      img {
        filter: invert(1);
      }
    }
  }
  .helpline {
    background: #222;
    max-width: 300px;
    text-align: center;
    padding: 10px;
    margin: 10px auto;
    border: 1px solid #333;
    border-radius: 10px;
    h3 {
      font-size: 21px;
      font-weight: 700;
    }
    a {
      color: var(--primaryClr);
      font-size: 15px;
      font-weight: 700;
    }
  }
  p {
    color: var(--whiteClr);
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 0;
  }
  .betfair {
    max-width: 110px;
    height: auto;
    filter: invert(1);
  }
  .footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  .paymentImages {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        padding: 8px;
        img {
          max-height: 46px;
        }
      }
    }
  }
  .footer-links {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #333;
    ul {
      text-align: center;
      padding: 1rem 0;
      li {
        font-size: 12px;
        font-weight: 300;
        line-height: 14px;
        display: inline-block;
        border-right: 1px solid #5c6060;
        &:last-child {
          margin-right: 0;
          border-right: 0;
          &::after {
            display: none;
          }
        }
        a {
          color: var(--whiteClr);
          padding: 0 15px;
          opacity: 0.8;
          &:hover,
          &:focus {
            opacity: 1;
          }
        }
      }
    }
  }
  .social-icons {
    padding-top: 25px;
    ul {
      display: flex;
      justify-content: center;
      li {
        margin-right: 15px;
        position: relative;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 40px;
          height: auto;
          cursor: pointer;
        }
        svg {
          width: 32px;
        }
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .footer-menu {
    background: #323738;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    width: 100%;
    z-index: 9999;
    border-radius: 1rem 1rem 0 0;
    ul {
      li {
        text-transform: uppercase;
        font-size: 10px;
        flex: 1;

        a {
          color: var(--whiteClr);
          font-weight: 600;
          text-decoration: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 6px 5px 7px;
          cursor: pointer;
          .searchGames {
            background: transparent;
            border: 0;
            padding: 0;
            margin: 0;
            height: auto;
          }
          img {
            width: 18px;
          }
          span {
            margin-top: 5px;
          }
          &.active {
            color: var(--primaryClr);
            svg {
              fill: currentColor;
              stroke: currentColor;
            }
          }
        }
      }
      .FooterDropdown {
        text-transform: uppercase;
        font-size: 10px;
        flex: 1 1;
        display: flex;
        justify-content: center;
        button {
          color: var(--whiteClr);
          font-weight: 600;
          text-decoration: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 6px 5px 7px;
          cursor: pointer;
          background-color: transparent;
          border: 0;
          width: 100%;
          &::after {
            margin-left: 62px;
            margin-top: -12px;
          }
          img {
            width: 18px;
          }
          span {
            margin-top: 5px;
            font-weight: 600;
            font-size: 10px;
            text-transform: uppercase;
          }
        }
        .dropdown-menu {
          background-color: var(--secondaryClr);
          ul {
            display: flex;
            flex-direction: column;
            li {
              a {
                color: var(--whiteClr);
                display: flex;
                flex-direction: row;
                img {
                  width: 25px;
                  height: 25px;
                  background-color: var(--secondaryClr1);
                  border-radius: 4px;
                  padding: 5px;
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.agent-head.crypto-head {
  background: transparent;
}
.agent-head {
  display: flex;
  flex-wrap: wrap;
  background: var(--headClr);
  border-radius: 6px;
  padding: 10px;
  margin-top: 15px;
  .social-icon {
    width: 4%;
    margin: 0 1%;
    img {
      width: 100%;
    }
  }
}
.live_casino_page {
  // padding-top: 50px;

  // &.ezugi {
  //   background: rgb(35, 31, 32);
  // }
  &.live {
    background: var(--headClr);
    color: var(--whiteClr);
  }

  .header-bg {
    // background-color: #e3e2e2;
    .header {
      margin-bottom: 0;
    }

    .headerRight {
      fill: var(--primaryClr);
      .path-stroke {
        stroke: var(--primaryClr);
      }
    }
  }

  // @media screen and (min-width: 750px) {
  //   padding-top: 60px;
  // }

  // @media screen and (min-width: 1200px) {
  //   padding-top: 70px;
  // }

  scroll-snap-type: mandatory;

  .custom_placeholder_parent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(87, 84, 85, 0.84);

    .custom_placeholder_child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100px;
    }
  }
}
.button-primary.btn.btn-primary {
  padding: 6px 0 !important;
}
.form-control {
  background: transparent !important;
  color: var(--whiteClr) !important;
  &:focus {
    border-color: var(--primaryClr);
  }
}
.section-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  h2 {
    margin-bottom: 0 !important;
  }
  a {
    background: var(--primaryClr);
    color: var(--blackClr);
    font-size: 15px;
    font-weight: 600;
    font-family: sans-serif;
    height: 40px;
    margin-left: 8px;
    padding: 10px 20px;
    border: 0;
    border-radius: 25px;
    transition: all 0.4s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
}

.betslip-content.inline_betslip {
  .stack-win-value {
    display: flex;
    margin-bottom: 5px;
    > div {
      width: calc(33.33% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }

    .odds,
    .stack_field_value {
      .form-control {
        border-color: var(--secondaryClr);
      }
    }
  }
  .winning {
    p {
      line-height: 1.3;
      margin-bottom: 0;
      @media only screen and (max-width: 767.98px) {
        font-size: 0.8rem;
      }
    }
    > div {
      display: flex;
      gap: 5;
      button {
        padding: 1px 5px;
        height: 25px;
        width: 25px;
        border: 0;
        outline: 0;
        border-radius: 3px;
      }
    }
  }
  .auto-stack {
    margin-bottom: 10px;
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      li {
        width: calc(25% - 0.5rem);
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        border: 0;
        &:last-child {
          margin-right: 0;
        }
        .stack-btn {
          background: var(--primaryClr);
          color: var(--blackClr);
          width: 100%;
          padding: 5px;
        }
      }
    }
  }
  .btn_group {
    display: flex;
    > div {
      width: calc(50% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .bet-now {
      .place-btn {
        background: #2ebd97;
        color: var(--whiteClr);
        font-size: 1.15rem;
        font-weight: 600;
        width: 100%;
        &:hover {
          background: #025f18;
        }
      }
    }
    .cancel-btn {
      background: var(--lightClr);
      color: var(--siteRedClr);
      font-size: 1.15rem;
      font-weight: 600;
      width: 100%;
      &:hover {
        background: var(--siteRedClr);
        color: var(--whiteClr);
      }
    }
  }
}

.betslip_popup {
  padding-left: 0 !important;
  .betslip_content {
    border-radius: 4px 4px 0 0;
    .laybet {
      background-color: rgb(164 82 100) !important;
      border-top: 3px solid #f87592 !important;
      padding: 10px 10px 25px 10px;
    }
    .backbet {
      background-color: rgb(52 85 112) !important;
      border-top: 3px solid #68bbff !important;
      padding: 10px 10px 25px 10px;
    }
    input {
      background-color: var(--whiteClr) !important;
      color: #000 !important;
    }
    .auto-stack {
      ul li {
        width: calc(10% - 0.5rem);
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        padding-left: 0;
        background: var(--primaryClr);
        border: 0 !important;
        .stack-btn {
          background: var(--primaryClr);
          color: var(--blackClr);
          width: 100%;
          padding: 5px;
        }
      }
    }
  }

  .modal-body {
    background: var(--headClr);
    border: 1px solid #222;
    border-top-width: 1px;
    border-radius: 4px 4px 0 0;
    padding: 0;
    .table-responsive {
      padding: 0;
    }
    table {
      margin-bottom: 0;
      thead {
        background: #222;
        tr {
          th {
            border: 0;
            background: #222;
            color: var(--whiteClr);
          }
        }
      }
      tbody {
        tr {
          border-color: #333;
        }
        td {
          color: var(--whiteClr);
          &.yes {
            color: #72bbef;
          }
          &.no {
            color: #faa8ba;
          }
        }
      }
    }
  }
  .modal-footer {
    background: #444;
    padding: 7px 10px;
    border: 0;
    .btn {
      min-width: 100px;
      margin: 0 auto;
    }
  }
  &.outer_popup {
    .modal-body {
      table {
        thead {
          tr {
            th {
              @media screen and (max-width: 767.98px) {
                &:nth-child(3) {
                  min-width: 200px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.inner_popup {
    .modal-body {
      table {
        thead {
          tr {
            th {
              @media screen and (max-width: 767.98px) {
                &:not(:first-child) {
                  min-width: 80px;
                }
                :last-child {
                  min-width: 200px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.privay-content {
  margin-top: 20px;
  p.privacy-points {
    margin-bottom: 9px;
    width: 20%;
    position: relative;
    border-bottom: 1px solid var(--whiteClr);
    padding-left: 12px;
    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: var(--whiteClr);
      border-radius: 50%;
      top: 6px;
      left: 0;
    }
  }
}
.responsible-game {
  margin-top: 20px;
}
.exchange-popup {
  z-index: 99999999999;
  .modal-content {
    background: #1a1e29;
    border-radius: 0 !important;
    .modal-header,
    .modal-body,
    .modal-footer {
      border: 0;
      padding: 15px;
    }
    .btn-close {
      background-image: url(assets/images/close.svg);
      opacity: 1;
    }
    .balnace-wallet {
      display: flex;
      flex-direction: column;
      .balance-value {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        svg {
          width: 25px;
          height: 25px;
          margin-right: 5px;
          color: var(--primaryClr);
        }
      }
    }
    .amount-input {
      form {
        margin-top: 5px;
        input {
          background: var(--whiteClr) !important;
          border-width: 2px;
          border-radius: 5px;
          color: #000 !important;
        }
        .exchange-button {
          display: flex;
          justify-content: space-between;
          button {
            font-size: 16px;
            padding: 6px 8px;
          }
        }
      }
    }
  }
}
input[type="date"].form-control::-webkit-calendar-picker-indicator {
  filter: invert(1);
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.header.beforeheader {
  button.navbar-toggler {
    display: none;
  }
}
.page-details {
  .game-points {
    background: var(--headClr);
    padding-bottom: 0.05rem;
    border-radius: 0;
    .title {
      background: var(--whiteClr);
      border-bottom: 1px solid #7e97a7;
      span {
        color: var(--blackClr);
        font-size: 13px;
        font-weight: 600;
      }
    }
    ul {
      li {
        padding: 0 0 0 0.75rem;
        border-color: #333;
        &:last-child {
          border-bottom: 0;
        }
        .team-name {
          color: #bcbcbc;
        }
        .match_odds {
          .info-block {
            &:first-child {
              background: #d7e8f4 !important;
            }
            &:nth-child(2) {
              background: #b7d5eb !important;
            }
            &:nth-child(3) {
              background: #72bbef !important;
            }
            &:nth-child(4) {
              background: #faa8ba !important;
            }
            &:nth-child(5) {
              background: #efd3d9 !important;
            }
            &:last-child {
              background: #f6e5ea !important;
            }
            span {
              font-weight: 600;
            }
          }
        }
      }
    }
    &.bookmaker {
      background: #faf8d8;
      .game-top-area {
        .title {
          background: transparent;
          border: 0;
          span {
            color: #bdc4c7;
            padding: 0;
          }
        }
        .range {
          position: relative;
          left: auto;
          transform: unset;
          background: #bed5d8;
          padding: 5px 10px;
          margin: 4px 0;
          border-radius: 5px;
        }
      }
      ul {
        background: transparent;
        li {
          .match_odds {
            @media only screen and (max-width: 767.98px) {
              width: 140;
            }
            .back_all_odds {
              background: linear-gradient(
                90deg,
                rgba(130, 183, 221, 0.15) 0%,
                rgba(130, 183, 221, 0.8) 65%
              );
              display: flex;
              padding: 0.25rem;
              @media only screen and (max-width: 767.98px) {
                background-image: linear-gradient(
                  90deg,
                  rgba(151, 199, 234, 0.7) 0%,
                  #97c7ea 65%
                );
                width: 70px;
              }
              .info-block {
                &:nth-child(3) {
                  background: #72bbef !important;
                  border: 1px solid var(--whiteClr) !important;
                  border-radius: 4px;
                  span {
                    color: var(--blackClr);
                  }
                }
                @media only screen and (max-width: 767.98px) {
                  &:nth-child(1),
                  &:nth-child(2) {
                    display: none !important;
                  }
                  &:nth-child(3) {
                    display: block !important;
                    width: 100%;
                  }
                }
              }
              .bookmaker_susp {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4);
                color: var(--whiteClr);
                font-weight: bold;
                text-shadow: 0 0.2666666667vw 1.0666666667vw rgb(0 0 0 / 50%);
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                  opacity: 0.8;
                }
              }
            }
            .lay_all_odds {
              background: linear-gradient(
                270deg,
                rgba(231, 170, 184, 0.15) 5%,
                rgba(231, 170, 184, 0.8) 60%
              );
              display: flex;
              padding: 0.25rem;
              @media only screen and (max-width: 767.98px) {
                background-image: linear-gradient(
                  270deg,
                  rgba(247, 205, 214, 0.75) 5%,
                  #f0c0cb 60%
                );
                width: 70px;
              }
              .info-block {
                &:nth-child(1) {
                  background: #faa9ba !important;
                  border: 1px solid var(--whiteClr) !important;
                  border-radius: 4px;
                  span {
                    color: var(--blackClr);
                  }
                }
                @media only screen and (max-width: 767.98px) {
                  &:nth-child(1) {
                    display: block !important;
                    width: 100%;
                  }
                  &:nth-child(2),
                  &:nth-child(3) {
                    display: none !important;
                  }
                }
              }
            }
            .info-block {
              background: transparent !important;
              margin: 0 !important;
              line-height: 27px;
              span {
                color: #59708d;
              }
              @media only screen and (max-width: 767.98px) {
                &:nth-child(1) {
                  display: block !important;
                }
                &:nth-child(2),
                &:nth-child(3) {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .title {
    &.market_heading {
      background: var(--secondaryClr1);
      span {
        font-weight: bold;
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .header_open {
    .navbar {
      transform: translateX(0px) !important;
      .navbar-toggler {
        right: -12px !important;
      }
    }
  }
  .header {
    .navbar {
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-230px);
      width: 230px;
      height: 100%;
      background: var(--bodyClr);
      padding: 1rem;
      z-index: 9;
      transition: all 0.3s;
      .navbar-toggler {
        position: absolute;
        top: 70px;
        right: -30px;
        background: var(--whiteClr);
        padding: 0.15rem 0.25rem;
        border-radius: 25px;
        z-index: 99;
        .navbar-toggler-icon {
          width: 20px;
          height: 22px;
        }
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
      .navbar-collapse {
        position: fixed;
        top: 1rem;
        left: 1rem;
        width: 200px;
        height: 100%;
        display: block !important;
        background: transparent;
        padding: 0;
        margin: 0;
        .navbar-nav {
          width: 100%;
          flex-direction: column;
          a {
            color: var(--whiteClr);
            font-size: 1em;
            text-transform: capitalize;
            width: 100%;
            height: 30px;
            line-height: 1.33;
            margin-bottom: 1.1rem;
            padding: 0;
            &:last-child {
              margin-bottom: 0;
            }
            svg,
            img {
              fill: var(--whiteClr);
              width: 20px;
              height: 20px;
              margin-right: 7px;
            }
            &:hover {
              opacity: 0.8;
            }
            &.logoImg {
              width: fit-content;
              margin-bottom: 2.25rem;
              img {
                width: 150px;
                height: auto;
                @media screen and (max-width: 767.98px) {
                  width: 120px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .DepositHomePage .DepositHomeContentt .MiddlePart img {
    width: 2.6rem !important;
  }
  .referr_tabs {
    ul {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      li {
        button {
          white-space: nowrap;
        }
      }
    }
  }
  .cards {
    width: 100% !important;
  }
  .agent-head {
    justify-content: space-between;
    .social-icon {
      width: 16%;
      margin: 1%;
    }
  }
  .header {
    .navbar {
      .navbar-toggler {
        border-radius: 0.375rem;
        line-height: 16px;
        padding: 0 0.15rem;
        right: -37px;
        top: 16px;
      }
    }
    .headerRight {
      .menu-btn {
        margin-left: 0;
      }
      .funds {
        ul {
          margin-right: 0px;
          padding: 0px !important;
          li {
            margin: 0 4px !important;
            a {
              color: var(--whiteClr);
              display: block;
              border-radius: 22px;
              span {
                padding-left: 2px;
              }
            }
          }
          li:after {
            display: none;
          }
        }
      }
      ul {
        li {
          margin: 0 2px;
        }
      }
    }
  }
  .custom-tabs {
    padding-top: 0px;
    .sports-name-listing {
      ul {
        padding: 5px;
        margin-bottom: 5px;
      }
    }
  }
  .page-details {
    .game-top-area {
      .range {
        display: none;
      }
      .date {
        display: none;
      }
    }
    .game-points {
      ul {
        li {
          display: flex;
          padding-left: 0.5rem;
          border-bottom: 1px solid #2f3940;
          .team-name {
            width: calc(100% - 150px);
            flex-direction: revert;
            justify-content: space-between;
            color: var(--whiteClr);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 0.25rem 0 0;
          }
          .status-btns {
            width: 140px;
            display: flex;
            align-items: center;
            .info-block {
              width: inherit;
              display: none;
              &:nth-child(3),
              &:nth-child(4) {
                display: flex;
                width: 50%;
              }
              &:nth-child(4) {
                border: 0;
              }
              &:first-child {
                margin-left: 0;
                display: none;
              }
            }
            .info-block.no:nth-child(2) {
              display: flex;
              width: 50%;
            }
          }
        }
      }
    }
  }
  .sports_page,
  .custom-tabs {
    .tab-pane {
      .game-listing {
        .match-info {
          display: block;
          .match-name {
            width: 100%;
          }
          .live-match {
            height: 10px;
            width: 10px;
            margin-right: 8px;
            background: #c4cfd6;
            border: 1px solid #366601;
            border-radius: 50%;
          }
          .listing {
            padding-bottom: 5px;
            li {
              width: 100%;
              margin-right: 3px;
              &:nth-child(2),
              &:nth-child(4) {
                margin-right: 6px;
              }
            }
          }
        }
        .match-listing-head {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .header {
    .headerRight {
      .funds {
        ul {
          li {
            a {
              display: flex;
              border-radius: 22px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
.mybets-tabs-section {
  ul {
    width: max-content;
    display: flex;
    border: 1px solid #545454;
    border-top: 1px solid #545454 !important;
    margin-bottom: 20px !important;
    @media only screen and (max-width: 767px) {
      overflow-x: scroll;
      width: auto;
      margin: 15px 0px !important;
    }
    li {
      padding: 8px 15px !important;
      border-bottom: 0 !important;
      border-right: 1px solid #545454;
      white-space: nowrap;
      &:hover {
        background-color: var(--primaryClr);
      }
      &.active {
        background-color: var(--primaryClr);
      }
      div {
        color: var(--whiteClr);
      }
    }
  }
}

.account-statement {
  table {
    tbody {
      tr {
        td {
          span.lay {
            background: #faa8ba;
            border-radius: 5px;
            font-size: 11px;
            color: #000;
            padding: 5px;
            width: 40px;
            justify-content: center;
            display: flex;
          }
        }
        td {
          span.back {
            background-color: #72bbef;
            border-radius: 5px;
            font-size: 11px;
            color: #000;
            padding: 5px;
            width: 40px;
            justify-content: center;
            display: flex;
          }
        }
      }
    }
  }
}
.transfer_info_page {
  input {
    border-color: #585858 !important;
    &::placeholder {
      color: var(--whiteClr);
      font-weight: bold;
    }
  }
  button[type="submit"] {
    margin-top: 15px;
  }
  .upi-list {
    li {
      border: 0 !important;
      margin-top: 20px;
    }
  }
}
.panel {
  svg {
    fill: white;
  }
}

.whtasapp-all-page {
  position: fixed;
  right: 10px;
  z-index: 9;
  bottom: 125px !important;
  width: 135px;
  height: 40px;
}
.confirm-title {
  color: black;
}
.edit-btn {
  svg {
    fill: #000;
  }
}

.bookmaker {
  background: var(--headClr) !important;
  span {
    background-color: transparent !important;
    margin-left: 0 !important;
  }
  .status-btns {
    height: 44px;
    width: 408px;
  }
  .points-status {
    width: 100%;
    top: 3px !important;
    width: 100% !important;
    height: 40px !important;
    margin: 0 !important;
    &::after {
      height: 29px !important;
      top: 5px !important;
    }
  }
}

@-webkit-keyframes sports-book-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50%,
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes sports-book-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50%,
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes sports-book-bouncing {
  0% {
    -webkit-transform: translateY(-60%);
    transform: translateY(-60%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  20% {
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  32.5% {
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  41% {
    -webkit-transform: translateY(-8%);
    transform: translateY(-8%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  46.5% {
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  12.5%,
  27.5%,
  37.5%,
  43.5% {
    -webkit-transform: translateY(0) scale(1.1, 0.9);
    transform: translateY(0) scale(1.1, 0.9);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes sports-book-bouncing {
  0% {
    -webkit-transform: translateY(-60%);
    transform: translateY(-60%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  20% {
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  32.5% {
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  41% {
    -webkit-transform: translateY(-8%);
    transform: translateY(-8%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  46.5% {
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  12.5%,
  27.5%,
  37.5%,
  43.5% {
    -webkit-transform: translateY(0) scale(1.1, 0.9);
    transform: translateY(0) scale(1.1, 0.9);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.sessionmarket .game-points ul li .status-btns .info-block:nth-child(2) {
  display: flex !important;
  width: 68px !important;
}
.webslider {
  display: flex;
  .imgBox {
    margin: 0 8px;
    width: 33.33%;
  }
}

.bet-liability-markets {
  border-radius: 5px;
  margin: 4px 0px;
  color: var(--blackClr);
  padding: 0px 5px;
  background-color: var(--primaryClr);
}

.animate-img {
  position: relative;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  .spin-anim {
    width: 100%;
    height: 100%;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;

    .img-spinbg {
      position: absolute;
      left: 50%;
      width: 1.9375rem !important;
      height: 1.9375rem !important;
      z-index: 1;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translate(-50%);
      top: 6%;
      opacity: 0;
    }
    .img-turntable {
      -webkit-animation: rotate-e1vxjz6v 2s infinite linear;
      animation: rotate-e1vxjz6v 2s infinite linear;
      z-index: 2;
    }
    .img-pointer {
      position: absolute;
      top: -6px;
      left: 40%;
      transform: translate(-50%);
      width: 15px !important;
      height: auto !important;
      z-index: 3;
      margin-right: 0 !important;
    }
  }
}

@-webkit-keyframes rotate-e1vxjz6v {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-e1vxjz6v {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes game-category__icon-floating {
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes game-category__icon-floating {
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@-webkit-keyframes game-category__icon-floating {
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes game-category__icon-floating {
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes loginbtnpopup {
  0% {
    box-shadow: 0 0 0 0 rgba(29, 128, 58, 0.7);
    transform: scale(0.95);
  }

  70% {
    box-shadow: 0 0 0 10px transparent;
    transform: scale(1);
  }

  to {
    box-shadow: 0 0 0 0 transparent;
    transform: scale(0.95);
  }
}
.sign-in-btn {
  animation: loginbtnpopup 2s infinite;
  box-shadow: 0 0 0 0 #1d803a;
  color: #fcfcfc;
  font-size: 22px;
  font-weight: 800;
  height: 40px;
  line-height: 24px;
  transform: scale(1);
}
@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
.payment-info.social {
  background-color: transparent !important;
  position: fixed;
  right: 0;
  top: 30%;
  width: 61px;
  margin: -30px -1rem;
  z-index: 99;

  ul {
    flex-direction: column;
    position: relative;
    li {
      margin-bottom: 10px;
      a {
        align-items: center;
        background-color: rgb(0 0 0 / 56%);
        border: 2px solid #4a4a4a;
        border-radius: 5px;
        display: flex;
        height: 45px;
        position: relative;
        right: 15px;
        transition: all 0.3s;
        width: 70px;
        cursor: pointer;
        &:hover {
          right: 150px;
          width: 200px;
        }
        img {
          height: auto;
          margin: 0 11px;
          width: 25px;
        }
        p {
          color: var(--whiteClr);
          display: flex;
          // flex-direction: column;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          margin: 0;
          span {
            padding-left: 10px;
          }
        }
      }
    }
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  background: var(--secondaryClr2);
  padding: 10px;
  align-items: center;
  h4 {
    margin: 0;
  }
}
.cards {
  span {
    color: orange;
    margin-left: 4px;
  }
  border: 1px solid #e56931;
  padding: 15px;
  border-radius: 10px;

  width: 32.33%;
  margin: 0.5%;
}
.amount_data {
  justify-content: space-between;
}
.date_bonus {
  justify-content: space-between;
}
.bonus {
  h6 {
    margin-top: 10px;
    font-weight: 600;
    color: #e56931;
  }
  border-top: 1px solid gray;
  margin-top: 10px;
  border-bottom: 1px solid gray;
}
.comments {
  .accordion-header {
    button {
      font-weight: 600;
      background: #031c2a;
      color: white;
      &::after {
        filter: invert(1);
      }
    }
  }
  .accordion-item {
    border: none;
  }
  margin-top: 10px;
}
.cancel_bonus {
  background: red;
  padding: 1px 2px;
  font-size: smaller;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
}
.reqDate {
  font-weight: 700;
  margin-bottom: 10px;
}
.date_new {
  font-weight: normal;
  font-size: 12px;
}
.amount_data {
  .status {
    color: green;
  }
  .status_claimed {
    color: green;
  }
  .staus_pending {
    color: rgb(79 79 255);
  }
}
.bonus_table {
  color: white;
  // border: 2px solid #353c4b;
  th {
    white-space: nowrap;
    padding-inline: 10px;
  }
  tr {
    background: #16394c;
  }
}
.main_card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.casino_sec .slick-initialized .slick-slide {
  width: 16.6% !important;
}
.casino_sec .slick-track {
  display: flex;
}
.new_table {
  thead {
    background: var(--secondaryClr2);
    padding: 6px;
    th {
      background: var(--secondaryClr2);
      padding: 5px;
      color: #fff;
    }
  }
  body {
    background: var(--blackClr);
    td {
      background: var(--blackClr);
    }
  }
}
.primary_card {
  border-radius: 10px;
  border: 1px solid #d58b2f;
  .stats-card {
    display: flex;
    ul {
      border-right: 1px solid #353c4b;
      width: 50%;
      &:last-child {
        border: 0;
      }
      li {
        // height: 50px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #353c4b;
        font-weight: 700;
        span {
          width: 50%;
          // font-size: 13px;
          display: block;
          font-weight: normal;
        }
      }
    }
  }
}
.date_Bonus {
  font-size: 11px;
}
// .primaryCard_td {
//   background: #353c4b;
// }

.progressbar-section {
  .progress-bar {
    min-width: 5% !important;
  }
}
.referral_link {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: var(--blackClr);
  padding: 6px 20px;
  gap: 10px;
  @media (max-width: 767.98px) {
    margin-top: 10px !important;
    padding: 6px 10px;
  }
  h5 {
    margin-right: 10px;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
  }
  div {
    background: var(--bodyClr);
    color: #e7e4e4;
    padding: 8px 15px;
    border-radius: 5px;
    flex: 1;
  }
  .btn-primary {
    height: 35px;
    padding: 4px 7px;
    svg {
      width: 18px;
      height: auto;
    }
  }
}
.referr_user {
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    font-size: x-large;
    font-weight: 500;
    margin-bottom: 0;
  }
  svg {
    font-size: large;
  }
}
.referral_card_main {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 767.98px) {
    gap: 10px;
    margin-top: 10px !important;
  }
  .referrals_cards {
    background: var(--blackClr);
    border-radius: 5px;
    flex: 1;
    @media (max-width: 767.98px) {
      padding: 0.75rem !important;
    }
    &:not(:last-child) {
      @media (max-width: 767.98px) {
        width: calc(50% - 5px);
      }
    }
    &:last-child {
      @media (max-width: 767.98px) {
        width: 100%;
        flex: auto;
      }
    }
    .referral_card_icon {
      color: #08aadb;
    }
    h5 {
      font-size: 1rem;
    }
  }
}
.referr_tabs {
  ul {
    gap: 20px;
    border-bottom: 0;
    li {
      button {
        background: var(--secondaryClr1) !important;
        color: var(--whiteClr);
        border: 0;
        border-radius: 5px 5px 0 0;
        padding: 8px 35px;
        &.active {
          background: var(--headClr) !important;
          color: var(--whiteClr) !important;
          border: none;
          padding: 8px 35px;
        }

        &:hover {
          background: none;
          border: none;
          color: var(--whiteClr);
        }
      }
    }
  }
}
.referr_table {
  color: var(--whiteClr);
  thead {
    th {
      background: var(--secondaryClr2);
      color: var(--whiteClr);
      border-color: var(--secondaryClr2);
    }
  }
  tbody {
    td {
      background: var(--blackClr);
      color: var(--whiteClr);
      border-color: var(--blackClr);
    }
  }
  .dropdown-item {
    display: flex;
    justify-content: space-between;
    color: var(--whiteClr) !important;
    &:hover {
      background: none;
    }
  }
  .dropdown-menu {
    margin-top: 10px;
    background: var(--headClr);
  }
  .dropdown {
    button {
      background: var(--headClr);
      border: 0;
      &:hover,
      &:focus {
        background: var(--headClr);
      }
    }
  }
}

.gatewaySection {
  max-width: 800px;
  margin: 40px auto 15px;
  @media (max-width: 767.98px) {
    max-width: calc(100% - 30px);
    height: calc(100vh - 51px);
    margin: 20px auto 15px;
  }
  ul.nav-tabs {
    border: 0;
    margin-bottom: 0 !important;
    li {
      width: 50%;
      button {
        background: linear-gradient(
          0deg,
          var(--secondaryClr2) 0%,
          var(--blackClr) 100%
        );
        border-radius: 15px 15px 0px 0px;
        border: 0;
        width: 100%;
        color: var(--whiteClr);
        &.active {
          background: var(--gradientClr);
          border-radius: 15px 15px 0px 0px;
          color: var(--whiteClr);
        }
      }
    }
  }
  .tab-content {
    background: var(--secondaryClr2);
    padding: 20px 50px;
    @media (max-width: 767.98px) {
      padding: 20px 15px;
    }
    .tab-pane.active {
      display: flex;
      flex-wrap: wrap;
      .cards1 {
        cursor: pointer;
        background: linear-gradient(
          0deg,
          var(--secondaryClr2) 0%,
          var(--blackClr) 100%
        );
        border: 0.5px solid var(--blackClr);
        box-sizing: border-box;
        border-radius: 10px;
        width: 31.3333%;
        float: left;
        margin: 1% 1%;
        .card-icon {
          padding: 12px 5px 4px 5px;
          font-size: 12px;
          border-bottom: 0.5px solid var(--blackClr);
          text-align: center;
          img {
            width: 80px;
          }
        }
        p {
          padding: 11px 4px;
          font-size: 14px;
          font-size: 12px;
          margin: 0;
          text-align: center;
        }
      }
    }
  }
}
.gatewayForm {
  background: var(--secondaryClr2);
  max-width: 600px;
  padding: 25px 15px;
  margin: 20px auto;
  border: 1px solid var(--blackClr);
  input {
    background: var(--bodyClr) !important;
    color: var(--whiteClr) !important;
    padding: 10px 15px;
    border-color: var(--blackClr) !important;
    border-radius: 5px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
    &::placeholder {
      color: #878787;
    }
  }
  button {
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 10px;
    min-width: 120px;
    height: 40px;
    padding: 6px 30px;
    text-transform: uppercase;
  }
}
.BankTransfer {
  background: var(--secondaryClr2);
  max-width: 600px;
  padding: 25px 15px;
  margin: 20px auto 0;
  border: 1px solid var(--blackClr);
  border-radius: 5px;
  @media (max-width: 575.98px) {
    padding: 15px 10px;
  }
  ul {
    background: var(--blackClr);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px 10px;
    border-radius: 5px;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    @media (max-width: 575.98px) {
      padding: 5px 7px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    li {
      &.lable {
        color: var(--whiteClr);
        width: 120px;
        @media (max-width: 575.98px) {
          width: 110px;
        }
      }
      &.info {
        background: var(--bodyClr);
        color: var(--whiteClr);
        width: calc(100% - 120px);
        height: 35px;
        line-height: 35px;
        padding: 0 10px;
        border-radius: 5px;
        position: relative;
        @media (max-width: 575.98px) {
          font-size: 12px;
          width: calc(100% - 110px);
          height: 30px;
          line-height: 30px;
          padding: 0 7px;
        }
        svg {
          position: absolute;
          top: 0;
          right: 0;
          width: 35px;
          height: 35px;
          background: linear-gradient(
            90deg,
            rgb(225, 177, 69) 0%,
            rgb(202, 143, 5) 50%,
            rgb(234, 187, 86) 100%
          );
          padding: 5px 10px;
          cursor: pointer;
          border-radius: 0 5px 5px 0;
          @media (max-width: 575.98px) {
            width: 30px;
            height: 30px;
            padding: 5px 7px;
          }
        }
      }
    }
  }
}
.playerBankDetails {
  color: var(--whiteClr);
  input {
    border-color: #4a5d6b !important;
    padding: 14px 20px;
    color: var(--whiteClr);
    &::placeholder {
      color: var(--whiteClr);
    }
  }
  button {
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 10px;
    min-width: 120px;
    padding: 9px 30px;
    text-transform: uppercase;
  }
}

.playerBankDetailsEdit {
  margin: 25px;
  color: #000000;

  input {
    border-color: #4a5d6b !important;
    padding: 14px 20px;
    color: #000000 !important;
    &::placeholder {
      color: #000000;
    }
  }
  button {
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 10px;
    min-width: 120px;
    padding: 9px 30px;
    text-transform: uppercase;
  }
}

.updateBankDeatils {
  color: #000 !important;
}

.AccountStatemntBal {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  gap: 12px;
  .balance,
  .balance-amount {
    width: calc(50% - 6px) !important;
  }
  button {
    width: calc(50% - 6px) !important;
  }
}

.LoaderImg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  img {
    width: 200px;
    height: 200px;
  }
}

.tabs-area {
  margin-top: 10px;
  ul {
    flex-wrap: nowrap;
    li {
      width: 100%;
      &:not(:last-child) {
        border-right: 1px solid var(--whiteClr);
      }
      .nav-link {
        width: 100%;
        background: var(--headClr);
        color: rgba(255, 255, 255, 0.6);
        border-radius: 0;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 17px 10px;
        border: none;
        &.active {
          background: var(--headClr);
          color: var(--whiteClr);
        }
      }
    }
  }
  .tab-content {
    .bank-details {
      margin-top: 22px;
      background: var(--secondaryClr);
      box-shadow: 5px 5px 10px #0000001a;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 20px;
      .deposit-head {
        text-align: center;
        padding: 0;
        background: var(--whiteClr);
        border-radius: 0;
        width: 31.33%;
        margin: 0 6px 15px;
        height: auto;
        .deposit-img {
          padding: 20px;
          img {
            max-width: 300px;
            height: 100px;
            @media only screen and (max-width: 767px) {
              height: auto;
            }
          }
        }
        .bkash {
          img {
            width: 100%;
          }
        }
        .deposit-name {
          background-color: #1ea0f8;
          height: 60px;
          align-items: center;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
      }
      .top-area {
        background: var(--headClr);
        width: 100%;
        font-size: 14px;
        padding: 20px;
      }
      .bottom-area {
        padding: 20px;
        table {
          margin-bottom: 0;
          th {
            color: var(--whiteClr);
            font-size: 18px;
            font-weight: 500;
            padding: 5px 0;
          }
          td {
            color: var(--whiteClr);
            font-size: 14px;
            font-weight: 300;
            padding: 5px 0;
            width: 50%;
          }
        }
      }
    }
    .upi-list {
      margin-top: 40px;
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          margin: 0 7px;
          width: 32px;
          border: none;
        }
      }
    }
    form {
      padding: 15px 30px 0;
      text-align: center;
      @media (max-width: 767.98px) {
        padding: 15px 15px 0;
      }
      &.playerBankDetails {
        @media (max-width: 767.98px) {
          margin: 0;
        }
      }
      input {
        background: var(--headClr);
        border-radius: 10px;
        border-color: var(--headClr);
        padding: 14px 20px;
        &::placeholder {
          color: #878787;
        }
        &.form-check-input {
          border-radius: 50px;
          width: 24px;
          height: 24px;
          border: 2px solid var(--primaryClr);
          padding: 0;
          background-color: var(--whiteClr);
          position: relative;
          &:checked:after {
            content: "";
            background: var(--headClr);
            border-radius: 50px;
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
          }
        }
      }
      .btn {
        padding: 9px 30px;
        font-size: 14px;
        text-transform: uppercase;
        min-width: 120px;
        height: 40px;
        @media (max-width: 767.98px) {
          padding: 9px 10px;
        }
      }
      .radio-btns {
        display: flex;
        .form-check {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 300;
          .form-check-label {
            margin-left: 5px;
            margin-top: 3px;
          }
        }
      }
    }
    .card-holder {
      height: 80px;
      background: #1a212a;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 5px 5px 10px #0000001a;
      img {
        max-width: 100px;
      }
    }
  }
  .h4 {
    font-size: 14px;
    font-weight: 500;
  }
  .sectionTitle {
    &::after {
      background: var(--headClr);
      width: 100%;
    }
  }
}
.DepositModal {
  .modal-content {
    border: 0;
    .modal-header {
      padding: 10px 20px;
      background: var(--headClr);
      border: 0;
      img {
        width: 40px;
      }
      button {
        background-image: url(./assets/svg/withdraw/download-svg.svg);
      }
    }
    .modal-body {
      padding: 20px;
      background: var(--secondaryClr);
      border: 0;
      .deposit-section-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
          width: 220px;
          &::placeholder {
            color: var(--whiteClr);
          }
        }
        p {
          margin-bottom: 0;
          color: #dfdfdf;
          font-size: 14px;
          font-weight: 600;
        }
      }
      .confirmBtn {
        a {
          width: 100%;
          margin-top: 20px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
        }
      }
    }
  }
}
.copy-name {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 12px;
  border: 1px solid var(--headClr);
  border-radius: 5px;
  padding: 10px 15px;
  h5 {
    margin-bottom: 0;
    font-size: 18px;
    color: var(--whiteClr);
    font-weight: 700;
  }
  p {
    margin-bottom: 0;
    font-size: 16px;
    color: var(--whiteClr);
    text-align: right;
    svg {
      margin-left: 8px;
    }
    p {
      font-size: 12px;
    }
  }
}
.deposit-form {
  input {
    border-radius: 10px;
    border-color: var(--headClr);
    padding: 14px 20px;
    &::placeholder {
      color: var(--whiteClr);
    }
  }
}
.payment-button {
  button {
    border-radius: 50px;
  }
  button.btn-danger {
    margin-left: 15px;
  }
}

.WithDrawSection {
  width: 100% !important;
  max-width: 760px;
  margin: 15px auto 25px;
  .WithdrawRequestSection {
    .tab-content {
      background: var(--secondaryClr2);
      padding: 10px 15px;
      margin-top: 10px;
      border-radius: 5px;
      @media (max-width: 767.98px) {
        padding: 10px 5px;
      }
      form {
        padding: 0;
        .form-control {
          background: var(--bodyClr) !important;
          color: var(--whiteClr) !important;
          padding: 10px 15px;
          border-color: var(--blackClr) !important;
          border-radius: 5px !important;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
        }
      }
    }
  }
  &.tabs-area {
    ul {
      border: 0;
      margin-bottom: 10px;
      li {
        border: 0;
        button {
          background: var(--secondaryClr2);
          color: var(--whiteClr) !important;
          font-weight: 400;
          text-align: center;
          width: 97%;
          padding: 7px 5px;
          margin: 0 auto;
          border-radius: 4px;
          &.active {
            background: var(--gradientClr) !important;
            color: var(--whiteClr) !important;
          }
        }
      }
    }
  }
  .tab-content {
    thead {
      th {
        background: var(--secondaryClr2);
        color: var(--whiteClr);
        border-color: var(--secondaryClr2);
      }
    }
    tbody {
      th,
      td {
        background: var(--blackClr);
        color: var(--whiteClr);
        border-color: var(--secondaryClr2);
      }
    }
    .WithDrawBankDetails {
      background: var(--secondaryClr2);
      padding: 10px 15px;
      margin-top: 10px;
      border-radius: 5px;
      @media (max-width: 767.98px) {
        padding: 10px 5px;
      }
      .form-control {
        background: var(--bodyClr) !important;
        color: var(--whiteClr) !important;
        padding: 10px 15px;
        border-color: var(--blackClr) !important;
        border-radius: 5px !important;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
      }
      .playerBankDetails {
        border-bottom: 1px solid var(--blackClr);
        padding: 20px 0;
        margin-bottom: 20px;
        select {
          background: var(--bodyClr);
          color: var(--whiteClr);
          height: 45px;
          border: 1px solid var(--blackClr);
          border-radius: 5px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
        }
      }
      .WithdrawTable {
        td {
          .btn:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
    }
    thead {
      th {
        background: var(--bodyClr);
        &:first-child {
          min-width: 50px;
        }
      }
      th:not(first-child) {
        min-width: 160px;
      }
    }
    .WithdrawRequestBankSection {
      thead {
        th:nth-child(2) {
          min-width: 50px;
        }
      }
    }
    .nav-tabs {
      margin-top: 10px;
      border-bottom: 0;
      .nav-item {
        margin: 0 auto;
        &:first-child {
          border-right: 0 !important;
        }
      }
      .nav-link {
        padding: 8px 10px;
        &.active {
          background: var(--primaryClr) !important;
          color: var(--whiteClr) !important;
        }
      }
    }
  }
}
.changePasswordModal {
  .modal-header {
    background: var(--secondaryClr2);
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
    .btn-close {
      filter: invert(1);
    }
  }
  .modal-body {
    background: var(--bodyClr);
    input {
      &::placeholder {
        color: #878787;
      }
    }
    .icon {
      position: absolute;
      top: 5px;
      right: 15px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .modal-footer {
    background: var(--bodyClr);
    border-color: var(--secondaryClr2);
    border-radius: 0 0 4px 4px;
    .btn-primary {
      height: 40px;
    }
  }
}
.casinoProvidersGames {
  padding: 15px 0;
  .tabs {
    display: flex;
    overflow-x: auto;
    margin-bottom: 1rem;
    li {
      list-style: none;
      margin: 3px;
      position: relative;
      &:first-child {
        margin-left: 0;
      }
      &:after {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        width: 0%;
        height: 3px;
        background: conic-gradient(from 1turn, #71af70, #80d474);
        background: var(--gradientClr);
        border-radius: 12px;
        transition: all 0.5s;
      }
      &:hover,
      &.active,
      &[style="background: rgb(24, 40, 56);"] {
        &:after {
          width: 100%;
        }
      }
      a {
        background: var(--blackClr);
        color: var(--whiteClr);
        width: max-content;
        display: block;
        padding: 6px 15px;
        border-radius: 3px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
      }
      &.active {
        a {
          color: #27ae60;
        }
      }
    }
  }
  .games {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    li {
      width: calc(16.66% - 8.33px);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      @media only screen and (max-width: 991.98px) and (min-width: 576px) {
        width: calc(25% - 7.5px);
      }
      @media only screen and (max-width: 575.98px) {
        width: calc(50% - 5px);
      }
      a {
        background: var(--blackClr);
        display: block;
        overflow: hidden;
        cursor: auto;
        position: relative;
        z-index: 5;
        border: 4px solid var(--blackClr);
        border-radius: 5px;
        transition: all 0.3s;
        .game-img {
          border-radius: 4px 4px 0 0;
          transition: all 0.3s;
        }
        .play-btn {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.65);
          display: none;
          transition: all 0.4s;
          border-bottom: 1px solid var(--blackClr);
          border-radius: 4px;
          img {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -80%);
            width: 40px;
            cursor: pointer;
          }
        }
        .name {
          background: var(--blackClr);
          color: rgba(255, 255, 255, 0.6);
          font-size: 12px;
          font-weight: 300;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 3px 5px 0;
          border-radius: 0 0 4px 4px;
          transition: all 0.3s;
          z-index: 9;
          position: relative;
        }
        &:hover {
          transform: scale(1.3);
          z-index: 6;
          box-shadow: 0 0.125rem 0.375rem 0.1875rem rgba(0, 0, 0, 0.5),
            0 0.1875rem 0.3125rem 0.5rem rgba(0, 0, 0, 0.3);
          @media only screen and (max-width: 1319.98px) {
            transform: scale(1.1);
          }
          .play-btn {
            display: block;
          }
        }
      }
    }
  }
}

.ProviderSection {
  h3 {
    color: var(--whiteClr);
    position: relative;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .slick-arrow {
    background-color: var(--secondaryClr1);
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    display: flex !important;
    align-items: center;
    justify-content: center;
    &.slick-prev {
      left: auto;
      right: 35px;
      top: -34px;
      transform: rotate(180deg);
      &::before {
        content: "";
        background-image: url(./assets/images/icons/right-arrow.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 12px;
        height: 12px;
        display: block;
        line-height: inherit;
      }
    }
    &.slick-next {
      left: auto;
      right: 0;
      top: -33px;
      transform: rotate(181deg);
      &::before {
        content: "";
        background-image: url(./assets/images/icons/right-arrow.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(180deg);
        width: 12px;
        height: 12px;
        display: block;
        line-height: inherit;
      }
    }
  }
  .slick-list {
    li {
      width: 100%;
      margin: 0 10px 0 0;
      display: flex;
      a {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: var(--blackClr);
        margin-right: 10px;
        padding: 12px;
        border-radius: 5px;
        @media (max-width: 1449.98px) and (min-width: 1200px) {
          min-height: 165px;
          align-items: center;
        }
        @media (max-width: 359.98px) {
          min-height: 200px;
          align-items: center;
        }
        img {
          width: 130px;
          height: 130px;
        }
        p.quotes {
          font-size: 12px;
          margin-right: 8px;
        }
        p.providerName {
          font-size: 15px;
          font-weight: 800;
          color: var(--primaryClr);
        }
      }
      .play-btn {
        display: none;
      }
    }
  }
  ul {
    li {
      width: 10px;
      height: 10px;
      &.slick-active {
        button {
          background-color: var(--primaryClr);
          &::before {
            color: var(--primaryClr);
          }
        }
        &:hover {
          button {
            &::before {
              color: var(--primaryClr);
            }
          }
        }
      }
      button {
        padding: 0;
        background-color: var(--whiteClr);
        border-radius: 50%;
        width: 10px;
        height: 10px;
        &::before {
          width: 10px;
          height: 10px;
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
}

.MaintainSection {
  display: flex;
  align-items: center;
  height: 100vh !important;
  padding: 0 40px;
  .MaintainSectionContent {
    width: 50%;

    .MaintainSection__title {
      font-size: 40px;
      line-height: 17px;
    }
    p.maintainword {
      text-transform: uppercase;
      color: var(--primaryClr);
      font-weight: 800;
      font-size: 50px;
      position: relative;
      margin-bottom: 70px;
      &::before {
        width: 55px;
        height: 5px;
        display: block;
        content: "";
        position: absolute;
        bottom: -2px;
        left: 30%;
        margin-left: -14px;
        background-color: var(--primaryClr);
      }
      &::after {
        width: 410px;
        height: 1px;
        display: block;
        content: "";
        position: relative;
        margin-top: 6px;
        left: 82px;
        margin-left: -78px;
        background-color: var(--primaryClr);
      }
    }
    p.backsoon {
      font-size: 40px;
      color: #ffffff;
      line-height: 13px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    p {
      font-size: 14px;
    }
  }
  .MaintainImg {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    img {
      width: 500px;
    }
  }
}
.banner-btn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  .btn {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 0;
    margin: 0;
    opacity: 0 !important;
  }
}
.search-box {
  .searchGames {
    background: var(--secondaryClr1);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    padding: 5px 10px;
    margin-right: 10px;
    border: 1px solid var(--blackClr);
    border-radius: 0.5rem;
    img {
      width: 22px;
    }
    @media (max-width: 767.98px) {
      width: auto;
      padding: 5px 6px;
    }
    svg {
      font-size: 20px;
    }
    p {
      color: var(--whiteClr);
      opacity: 0.5;
      padding-left: 10px;
      margin-bottom: 0;
      @media (max-width: 767.98px) {
        display: none;
      }
    }
  }
}
.search-modal {
  .modal-content {
    background: var(--bodyClr);
    border: 1px solid var(--blackClr);
    border-radius: 4px;
    .modal-header {
      background: var(--blackClr);
      border: 0;
      border-radius: 4px 4px 0 0;
    }
    .modal-body {
      background: var(--bodyClr);
      border-radius: 0 0 4px 4px;
      .form-control {
        border-color: var(--blackClr);
        border-radius: 4px;
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.5) !important;
        &::placeholder {
          color: var(--whiteClr);
          font-size: 12px;
          opacity: 0.5;
        }
      }
      .game-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        li {
          width: calc(50% - 5px);
          a {
            background: var(--blackClr);
            display: block;
            border-radius: 5px;
            box-shadow: 0 2px 12px rgba(0, 0, 0, 0.65);
            img {
              border-radius: 5px 5px 0 0;
            }
            span {
              background: var(--blackClr);
              color: rgba(255, 255, 255, 0.85);
              font-size: 12px;
              font-weight: normal;
              display: block;
              padding: 3px 5px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              border-radius: 0 0 5px 5px;
            }
          }
        }
      }
    }
  }
}

.ezugi_iframe,
.exchange_iframe {
  height: calc(100vh - 58px);
  width: 100%;
  overflow-y: hidden;
  @media (max-width: 767.98px) {
    height: calc(100vh - 55px);
  }
  iframe {
    height: calc(100vh - 81px) !important;
    @media (max-width: 767.98px) {
      height: calc(100vh - 55px) !important;
    }
    @media only screen and (min-device-width: 280px) and (max-device-width: 767.98px) {
      height: calc(100vh - 55px) !important;
      height: calc(var(--vh, 1vh) * 100 - 55px) !important;
    }
  }
}

.marqu {
  height: 25px;
  background: #201e1e;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
  img {
    width: 35px;
  }
  marquee {
    width: 100%;
  }
  span {
    position: absolute;
    width: 35px;
    height: 25px;
    top: 0;
    right: 0;
    background: #201e1e;
    display: flex;

    color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 15px;
  }
}

.game-modal {
  .modal-dialog {
    max-width: 400px !important;
  }
  .modal-content {
    background: var(--secondaryClr);
    border-radius: 10px;
    .modal-body {
      background: var(--secondaryClr);
      padding: 20px;
      border-radius: 10px;
      .content {
        text-align: center;
        h5 {
          color: var(--whiteClr);
          font-weight: 700;
          margin-bottom: 1rem;
        }
        p {
          color: var(--whiteClr);
          font-size: 16px;
          span {
            font-size: 13px;
            opacity: 0.8;
          }
        }
        .play-btn {
          padding-top: 20px;
          margin-top: 30px;
          border-top: 1px solid #525c71;
          div {
            background: var(--whiteClr);
            color: var(--secondaryClr);
            font-size: 16px;
            display: flex;
            align-items: center;
            width: fit-content;
            margin: 0 auto;
            padding: 10px 20px;
            border: 1px solid var(--whiteClr);
            border-radius: 5px;
            transition: all 0.3s;
            .img-box {
              background: var(--secondaryClr);
              width: 25px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              margin-left: 10px;
              border-radius: 50%;
              img {
                width: 12px;
                filter: invert(0.6);
                transition: all 0.3s;
              }
            }
            &:hover,
            &:focus {
              background: #525c71;
              color: var(--whiteClr);
              border-color: #525c71;
            }
          }
        }
      }
    }
  }
}

.KycSection {
  max-width: 800px;
  margin: 40px auto 15px;
  ul {
    border: 0;
    margin-bottom: 0 !important;
    li {
      width: 50%;
      button {
        background: linear-gradient(
          0deg,
          var(--secondaryClr2) 0%,
          var(--blackClr) 100%
        );
        border-radius: 15px 15px 0px 0px;
        border: 0 !important;
        width: 100%;
        color: var(--whiteClr);
        &.active {
          background: linear-gradient(180deg, #b08c4b 32.81%, #e4d385 100%);
        }
        &:hover {
          color: var(--whiteClr);
          background: linear-gradient(180deg, #b08c4b 32.81%, #e4d385 100%);
        }
      }
    }
  }
  .tab-content {
    background: var(--secondaryClr2);
    padding: 20px 50px;
    input {
      background: var(--bodyClr);
      color: var(--whiteClr);
      height: 45px;
      border: 1px solid var(--blackClr);
      border-radius: 5px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
      position: relative;
      &::placeholder {
        color: var(--whiteClr);
      }
    }
  }
}

.login-modal {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 50rem;
    }
    .modal-content {
      background: var(--bodyClr);
      border-radius: 16px;
      .modalSection {
        display: flex;
        .LoginBanner {
          width: 50%;
          img {
            border-radius: 16px 0 0 16px;
          }
        }
        .LoginRightPart {
          width: 50%;
          padding: 1.25rem 1.5rem;
          .modal-header {
            padding: 0;
            border: 0;
            .modal-title {
              font-size: 1.125rem;
              line-height: 1.75rem;
              font-weight: 800;
            }
            button.btn-close {
              background-image: url(./assets/images/icons/cross-remove-sign.webp);
            }
          }
          .modal-body {
            padding: 0;
            margin-top: 15px;
            .form-steps {
              padding: 0;
              .PhoneInput {
                input {
                  padding-left: 42px;
                  width: 100%;
                }
              }
              input {
                background: var(--secondaryClr2) !important;
                border-radius: 8px;
                padding: 8px 20px;
                border-color: var(--secondaryClr1) !important;
                height: 40px;
              }
              span.icon {
                position: absolute;
                right: 15px;
                top: 50%;
                img,
                svg {
                  width: 20px;
                  font-size: 20px;
                }
              }
              .form-link {
                color: #b3bec1;
                font-weight: 600;
              }
              button {
                width: 100%;
                font-weight: 700;
                margin-bottom: 22px;
              }
              .sign-up {
                text-align: left;
                a {
                  color: var(--primaryClr);
                  padding-left: 7px;
                  font-weight: 700;
                }
              }
              .get-otp {
                display: flex;
                .position-relative {
                  width: 100%;
                }
                button {
                  width: 135px;
                  position: absolute;
                  right: 0;
                  padding: 6px 20px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.HomeProviderSection {
  padding: 0 1rem;
  .HomeProvider {
    display: flex;
    gap: 0.75rem !important;
    align-items: stretch;
    @media (max-width: 767.98px) {
      gap: 0.6rem !important;
    }
    .HomeProviderLeftPart {
      display: flex;
      gap: 1.25rem !important;
      flex: 3 3 0;
      z-index: 9;
      @media (max-width: 767.98px) {
        gap: 0.5rem !important;
      }
      .LeftContent {
        padding: 1.25rem 1.75rem;
        height: 252px;
        grid-column-start: 1;
        border-radius: 0.75rem;
        position: relative;
        flex: 1;
        // box-shadow: 0 2px 22px #07a0ee;
        @media (max-width: 1549.98px) {
          padding: 1.25rem 1rem;
        }
        @media (max-width: 767.98px) {
          padding: 0.5rem !important;
          height: 110px !important;
        }
        &:first-child {
          // background-color: #07a0ee;
          // background-image: linear-gradient(90deg, #6c7ec7, #07a0ee, #6c7ec7);
          background: #323738;
          background-image: linear-gradient(
            to left,
            rgba(52, 51, 201, 0.7),
            transparent 75%
          );
          img {
            top: -40px;
            right: -5px;
            @media (max-width: 1499.98px) and (min-width: 1100px) {
              top: -30px;
              width: 230px;
            }
            @media (max-width: 1099.98px) and (min-width: 768px) {
              top: 35px;
              right: 15px;
            }
            @media (max-width: 767.98px) {
              top: 20px;
              right: 5px;
            }
          }
        }
        &:last-child {
          // background-color: #07a0ee;
          // background-image: linear-gradient(90deg, #6c7ec7, #07a0ee, #6c7ec7);
          background: #323738;
          background-image: linear-gradient(
            to left,
            // rgb(88, 46, 89),
            rgba(147, 53, 75, 0.7),
            transparent 75%
          );
          img {
            top: -55px;
            right: -20px;
            @media (max-width: 1499.98px) and (min-width: 1100px) {
              top: -45px;
            }
            @media (max-width: 1099.98px) and (min-width: 768px) {
              top: 25px;
              right: 15px;
            }
            @media (max-width: 767.98px) {
              top: 10px;
              right: 5px;
            }
          }
        }
        a {
          color: var(--whiteClr);
        }
        img {
          position: absolute;
          width: 280px;
          filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.65));
          @media (max-width: 1499.98px) {
            width: 260px;
          }
          @media (max-width: 1099.98px) and (min-width: 768px) {
            width: 180px;
          }
          @media (max-width: 767.98px) {
            width: 105px;
          }
        }
        .ImgContent {
          flex-direction: column;
          flex: 1 auto;
          display: flex;
          height: 100%;
          .HomeProviderheading {
            justify-content: baseline;
            display: flex;
            align-items: center;
            img {
              width: 1.7rem;
              position: static;
              margin-right: 8px;
            }
            span {
              font-size: 1.75rem;
              font-weight: 600;
              text-shadow: -2px 3px 5px rgb(0, 0, 0, 0.24);
              line-height: 2rem;
              @media (max-width: 767.98px) {
                font-size: 1rem;
              }
            }
          }
          p {
            margin-top: auto;
            max-width: 15rem;
            text-align: left;
            font-weight: 600;
            // color: #252525;
            color: #b3bec1;
            @media (max-width: 991.98px) {
              font-size: 13px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .HomeProviderRightPart {
      display: flex;
      gap: 0.75rem !important;
      flex-wrap: wrap;
      flex: 2 2 0;
      @media (max-width: 767.98px) {
        gap: 0.5rem !important;
      }
      .RightContent {
        min-width: 40%;
        height: 120px;
        padding: 1.25rem 0.75rem;
        grid-column-start: 1;
        border-radius: 0.75rem;
        position: relative;
        flex: 1;
        @media (max-width: 767.98px) {
          min-width: 20%;
          height: 90px;
          padding: 0.5rem 0;
        }
        &:first-child {
          background-color: #323738;
          background-image: linear-gradient(
            to left,
            // rgb(90 49 49),
            rgba(207, 18, 27, 0.7),
            transparent 75%
          );

          // background-color: #109dea;
          // background-image: linear-gradient(
          //   -45deg,
          //   rgb(209 15 27),
          //   transparent 100%
          // );

          // background-color: #07a0ee;
          // background-image: linear-gradient(
          //   90deg,
          //   rgba(207, 14, 25, 0.6),
          //   #07a0ee,
          //   rgba(207, 14, 25, 0.6)
          // );
        }
        &:nth-child(2) {
          background-color: #323738;
          background-image: linear-gradient(
            to left,
            // rgb(80, 65, 48),
            rgba(159, 68, 14, 0.7),
            transparent 75%
          );

          // background-color: #109dea;
          // background-image: linear-gradient(
          //   -45deg,
          //   rgb(164 96 9),
          //   transparent 75%
          // );

          // background-color: #07a0ee;
          // background-image: linear-gradient(
          //   90deg,
          //   rgba(164, 96, 9, 0.6),
          //   #07a0ee,
          //   rgba(164, 96, 9, 0.6)
          // );
        }
        &:nth-child(3) {
          background-color: #323738;
          background-image: linear-gradient(
            to left,
            // rgb(243 145 94 / 61%),
            rgba(184, 38, 40, 0.5),
            transparent 75%
          );

          // background-color: #109dea;
          // background,-image: linear-gradient(
          //   -45deg,
          //   rgb(111 14 11),
          //   transparent 75%
          // );

          // background-color: #07a0ee;
          // background-image: linear-gradient(
          //   90deg,
          //   rgba(111, 14, 11, 0.6),
          //   #07a0ee,
          //   rgba(111, 14, 11, 0.6)
          // );
        }
        &:last-child {
          background-color: #323738;
          background-image: linear-gradient(
            to left,
            // rgb(71, 56, 111),
            rgba(81, 64, 34, 0.9),
            transparent 75%
          );

          // background-color: #109dea;
          // background-image: linear-gradient(
          //   -45deg,
          //   rgb(191 165 81),
          //   transparent 75%
          // );

          // background-color: #07a0ee;
          // background-image: linear-gradient(
          //   90deg,
          //   rgba(191, 165, 81, 0.6),
          //   #07a0ee,
          //   rgba(191, 165, 81, 0.6)
          // );
        }
        a {
          color: var(--whiteClr);
        }
        img {
          position: absolute;
          bottom: -10px;
          right: -10px;
          width: 120px;
          height: auto;
          filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.65));
          &.racing {
            bottom: -18px;
            right: -20px;
          }
          &.mines {
            bottom: -5px;
            right: -20px;
            width: 130px;
            @media (max-width: 1199.98px) {
              width: 120px;
            }
          }
          @media (max-width: 1199.98px) {
            width: 110px;
            // right: 0px;
          }
          @media (max-width: 767.98px) {
            top: 5px !important;
            right: 50% !important;
            transform: translateX(50%) !important;
            width: 60px !important;
          }
        }
        .ImgContent {
          flex-direction: column;
          flex: 1 auto;
          display: flex;
          height: 100%;
          @media (max-width: 767.98px) {
            justify-content: end;
            align-items: center;
          }
          .HomeProviderheading {
            justify-content: baseline;
            display: flex;
            align-items: center;
            img {
              width: 24px;
              height: 24px;
              position: static;
              margin-right: 8px;
            }
            span {
              font-size: 1.5rem;
              line-height: 2rem;
              @media (max-width: 991.98px) and (min-width: 768px) {
                font-size: 1.15rem;
                line-height: 1.25rem;
              }
              @media (max-width: 767.98px) {
                font-size: 0.85rem;
                font-weight: 600;
                line-height: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

.home-iframe {
  padding: 0 2rem;
  .left-section {
    display: none;
  }
  .beforelogin-iframe,
  .aftrlogin-iframe {
    position: relative;
  }
  .beforelogin-iframe,
  .exchange_iframe {
    height: calc(100vh - 200px) !important;
    margin-bottom: 20px;
    @media (max-width: 767.98px) {
      height: calc(100vh - 220px) !important;
    }
    iframe {
      width: 100%;
      height: calc(100vh - 200px) !important;
      @media (max-width: 767.98px) {
        height: calc(100vh - 220px) !important;
      }
    }
  }
}

.DepositHomePage {
  .DepositHomeContentt {
    flex-direction: row-reverse !important;
    align-items: center;
    display: flex;
    padding: 0 2rem;
    background-image: url(./assets/images/banner/blackbackground.webp);
    height: 96px;
    border-radius: 0.5rem;
    .LastPart {
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-left: -0.25rem;
          width: 1.5rem;
        }
      }
    }
    .MiddlePart {
      gap: 1.5rem;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 4rem;
        margin: 0 8px;
      }
    }
    .FirstPart {
      h3 {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 800;
        span {
          color: var(--primaryClr);
        }
      }
    }
  }
}

.FooterPart {
  background-color: var(--secondaryClr2);
  padding: 2rem;
  display: flex;
  column-gap: 2.5rem;
  row-gap: 2.5rem;
  max-width: 100%;
  .LeftPart {
    width: 50%;
    h2 {
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 600;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #b3bec1;
    }
  }
  .RightPart {
    width: 50%;
    h2 {
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 600;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #b3bec1;
    }
    textarea {
      background-color: var(--secondaryClr);
      padding: 0.75rem 0.75rem 0.5rem;
      min-height: 8rem;
      font-weight: 600;
      border-radius: 0.5rem;
      border-color: #3a4142;
      width: 100%;
    }
    .btnEmail {
      margin-top: 12px;
      button {
        width: 50%;
        background: var(--secondaryClr);
        color: var(--whiteClr);
        opacity: 0.4;
        cursor: not-allowed;
        box-shadow: none;
        font-weight: 800;
        border: 0;
      }
      p {
        color: #b3bec1;
        font-weight: 600;
        margin: 0;
        font-size: 15px;
        a {
          color: var(--primaryClr);
        }
      }
    }
  }
}

.FooterCountrySection {
  ul {
    display: flex;
    flex-wrap: wrap;
    padding-top: 2rem;
    border-top: 1px solid #e4eaf019;
    border-bottom: 1px solid #e4eaf019;
    li {
      padding-left: 0.375rem;
      padding-right: 0.375rem;
      flex-basis: 50%;
      flex-grow: 0;
      max-width: 12.5%;
      margin-bottom: 1.75rem;
    }
  }
}
.FooterLogo {
  ul {
    display: flex;
    column-gap: 1.5rem;
    align-items: center;
    padding: 2rem 0;
    justify-content: space-between;
    li {
      img {
        width: 100px;
        @media (max-width: 991.98px) {
          width: 70px;
        }
      }
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        img {
          width: 50px;
        }
      }
    }
  }
}
.FooterLinksSection {
  border-top: 1px solid #e4eaf019;
  border-bottom: 1px solid #e4eaf019;
  padding: 1.5rem 1rem;
  row-gap: 1rem;
  column-gap: 1rem;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  .footerLinks {
    h2 {
      margin-bottom: 1.25rem;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 800;
      color: var(--whiteClr);
    }
    ul {
      flex-direction: column;
      row-gap: 0.75rem;
      display: flex;

      li {
        a {
          color: #b3bec1;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.25rem;
        }
      }
    }
    &:last-child {
      ul {
        flex-direction: row;
        gap: 0.5rem;
      }
      ul {
        li {
          a {
            background-color: var(--secondaryClr);
            width: 40px;
            height: 40px;
            padding: 10px;
            border-radius: 0.5rem;
            display: block;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.FooterLogoContent {
  display: flex;
  padding: 2rem 1rem;
  gap: 3rem;
  img {
    width: 90px;
    margin-bottom: 1.2rem;
  }
  p {
    color: #b3bec1;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
  }
}
.copyright {
  padding: 2rem 0.5rem;
  border-top: 1px solid #e4eaf019;
  margin-top: 2rem;
  p {
    color: #b3bec1;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    font-weight: 600;
  }
}

.header .leftbar_toggle .btn {
  background: var(--secondaryClr1) !important;
  width: 36px !important;
  height: 36px !important;
  padding: 6px !important;
  box-shadow: none !important;
  margin-right: 22px;
  img {
    width: 18px;
  }
}
.leftbarSec {
  background: #292d2e !important;
}
.leftbarSec .games_link ul li {
  margin-bottom: 5px !important;
  border-radius: 8px;
}
.leftbarSec .games_link ul li > div {
  background: #323738;
  height: 42px;
  line-height: 35px !important;
  border-radius: 8px;
  &:hover {
    background: linear-gradient(
      90deg,
      rgba(9, 9, 33, 1) 0%,
      rgba(52, 51, 201, 1) 100%
    );
  }
}
.leftbarSec .games_link button.accordion-button {
  background: #323738;
  height: 42px;
  line-height: 35px !important;
  border-radius: 8px;
  padding: 0 !important;
  &:focus {
    outline: 0;
    border: 0;
    box-shadow: none;
  }
  &:not(.collapsed) {
    box-shadow: none;
  }
  &::after {
    filter: invert(1);
    width: 0;
  }
  &:hover {
    background: linear-gradient(
      90deg,
      rgba(9, 9, 33, 1) 0%,
      rgba(52, 51, 201, 1) 100%
    );
  }
}
.leftbat-opened .leftbarSec .games_link button.accordion-button::after {
  width: 10%;
  opacity: 1;
  margin-right: inherit;
  margin-left: auto;
}
.leftbarSec .games_link button.accordion-button img {
  width: 31px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.leftbarSec .games_link ul li > div .img-box {
  width: 31px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftbarSec .games_link ul li > div .img-box img {
  width: 22px !important;
  filter: none !important;
}
.leftbarSec .games_link ul li > div:hover .img-box img {
  animation: none !important;
}

.leftbarSec .games_link .sidebaraccordian .accordion-item {
  border: 0;
  margin-bottom: 5px !important;
  background: #323738;
}
.leftbarSec
  .games_link
  .sidebaraccordian
  .accordion-item
  .accordion-body
  ul
  li
  a {
  display: flex;
  align-items: center;
  color: var(--whiteClr);
  padding: 10px;
}
.leftbarSec
  .games_link
  .sidebaraccordian
  .accordion-item
  .accordion-body
  ul
  li
  a
  img {
  width: 22px;
  height: 22px;
  margin-right: 15px;
}
.leftbarSec
  .games_link
  .sidebaraccordian
  .accordion-item
  .accordion-body
  ul
  li
  a
  span {
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 700;
  width: 0;
  overflow: hidden;
}
.leftbarSec .games_link .sidebaraccordian .accordion-item .accordion-body {
  padding: 0;
}
.leftbarSec .games_link .sidebaraccordian .img-box img {
  width: 22px !important;
  height: 22px !important;
  margin-right: 0 !important;
}
.home-iframe .aftrlogin-iframe .live_casino_page .d-xl-none {
  display: none !important;
}
.footer .footer-menu {
  z-index: 99999999 !important;
}
.leftbat-opened .leftbarSec .games_link button > div {
  width: auto !important;
}
.leftbarSec .games_link button.accordion-button > div {
  width: 50px;
  height: 42px;
  line-height: 35px !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 3px;
  justify-content: center;
  position: relative;
  .img-box {
    width: 31px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.leftbarSec .games_link button.accordion-button > div span {
  display: block;
  width: 0;
  overflow: hidden;
}
.leftbat-opened .leftbarSec .games_link button.accordion-button > div span {
  width: 100px;
  overflow: inherit !important;
  color: var(--whiteClr);
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 700;
}
.leftbat-opened
  .leftbarSec
  .games_link
  .sidebaraccordian
  .accordion-item
  .accordion-body
  ul
  li
  a
  span {
  width: 100px;
  overflow: inherit !important;
  color: var(--whiteClr);
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 700;
}
@media (min-width: 1200px) {
  .home-section {
    display: flex;
  }
  .leftbarSec {
    width: 70px !important;
    height: calc(100vh - 56px) !important;
  }
  .marqu ~ .main .leftbarSec {
    height: calc(100vh - 76px) !important;
  }
  .leftbat-opened .leftbarSec {
    width: 240px !important;
  }
  .leftbarSec ul li ul li > div span {
    width: 0;
    overflow: hidden;
  }
  .leftbat-opened .leftbarSec ul li ul li > div span {
    width: 100%;
    overflow: hidden;
    margin-left: 8px;
  }
}
.leftbat-opened .header {
  z-index: 999999999;
}
.leftbat-opened .leftbarSec ul li ul {
  background: transparent !important;
  padding: 0 !important;
  border-radius: 0 !important;
}
.leftbat-opened .leftbarSec ul li ul li::after {
  content: none !important;
}
@media (max-width: 1199.98px) {
  .leftbarSec {
    height: calc(100vh - 80px) !important;
    top: 80px !important;
    padding-top: 30px !important;
  }
}
@media (max-width: 991.98px) {
  .leftbarSec {
    height: calc(100vh - 55px) !important;
    top: 55px !important;
    padding-top: 30px !important;
    padding-bottom: 70px !important;
  }
}

@media (min-width: 1200px) {
  .right-section {
    width: calc(100% - 70px);
    height: calc(100vh - 56px);
    overflow-y: auto;
    padding-right: 9px;
  }
  .marqu ~ .main .right-section {
    height: calc(100vh - 76px);
  }
  .right-section::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .right-section::-webkit-scrollbar-thumb {
    background: #919595;
  }
  .right-section::-webkit-scrollbar-track {
    background: #e4e4e4;
  }
  .leftbat-opened .right-section {
    width: calc(100% - 240px);
  }
}
.Affiliate.right-section {
  footer {
    padding: 0 !important;
  }
}
.RecentBigWins {
  padding: 0 10px;
  h3 {
    position: relative;
    margin-bottom: 0;
    &::before {
      content: "";
      position: absolute;
      background: var(--primaryClr);
      border-radius: 50%;
      border: 10px solid var(--primaryClr);
      width: 10px;
      height: 10px;
      -webkit-animation: load 1.5s ease-out infinite;
      animation: load 1.5s ease-out infinite;
    }
  }
  .RecentTabs {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .Tabs {
      ul {
        display: flex;
        margin-left: 24px;
        gap: 0.5rem;
        li {
          width: auto;
          height: auto;
          color: #b3bec1;
          font-size: 0.875rem;
          line-height: 1rem;
          font-weight: 600;
          width: auto;
          height: auto;
          color: #b3bec1;
          font-size: 0.875rem;
          line-height: 1rem;
          font-weight: 600;
          padding: 0.25rem 0.45rem;
          cursor: pointer;
          &.active {
            border-bottom: 2px solid var(--primaryClr);
          }
        }
      }
    }
  }
  .slick-arrow {
    display: none !important;
  }
  .game_box {
    margin: 3px !important;
  }
}

@keyframes load {
  0% {
    transform: scale(2);
    opacity: 0;
  }
  50% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.casino-games {
  background-color: var(--bodyClr);
  .game-filter {
    gap: 10px;
    padding: 5px 0;
    margin: 10px;

    // background: var(--headClr);
    padding: 6px;
    @media only screen and (max-width: 767.98px) {
      box-shadow: none;
      padding: 0;
      margin: 5px 10px;
    }
  }
  .popular_game_sec {
    padding: 0 0 2rem;
    h3 {
      font-weight: 700;
      font-size: 18px;
    }
  }
  .categories {
    display: flex;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      display: none;
    }
    &::-webkit-scrollbar-track {
      background: var(--secondaryClr1);
    }
    &::-webkit-scrollbar-thumb {
      background: var(--secondaryClr2);
    }
    li {
      padding: 0 0.5rem;
      cursor: pointer;
      a {
        min-width: max-content;
        padding: 10px;
        border-radius: 0.75rem;
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: auto;
          margin-right: 5px;
          filter: grayscale(0.9);
        }
        span {
          color: #b3bec1;
          font-size: 13px;
          font-weight: 600;
        }
        &.active {
          background: var(--secondaryClr1);
          img {
            filter: inherit !important;
          }
          span {
            color: var(--whiteClr);
          }
        }
      }
    }
  }
  .providers {
    .dropdown-toggle {
      width: auto;
      text-align: left;
      background: var(--secondaryClr);
      border: 1px solid #3a4142;
      color: var(--whiteClr);
      margin-top: 1rem;
      font-size: 15px;
      border-radius: 0.75rem;
      padding: 9px 8px;
      display: flex;
      align-items: center;
      &::after {
        display: inline-block;
        margin-left: 5em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.4em solid;
        border-right: 0.4em solid transparent;
        border-bottom: 0;
        border-left: 0.4em solid transparent;
      }
    }
    .dropdown-menu {
      background: var(--secondaryClr);
      padding: 5px;
      border: 0;
      border-radius: 3px;
      .dropdown-item {
        background: var(--secondaryClr);
        color: rgba(255, 255, 255, 0.7);
        font-weight: 300;
        padding: 8px 10px;
        margin-bottom: 2px;
        border-bottom: 1px solid #1e293b;
        border-radius: 3px;
        &:last-child {
          border-bottom: 0;
        }
        &:hover,
        &:focus {
          background: var(--bodyClr);
          color: var(--whiteClr);
        }
      }
    }
  }
  .all-images {
    padding: 0 15px;
    @media only screen and (max-width: 767.98px) {
      padding: 10px;
    }
  }

  .all-images {
    ul {
      display: flex;
      flex-wrap: wrap;
      background: transparent !important;
      gap: 1rem;
      li {
        width: calc(16.6% - 0.8rem);
        height: auto !important;
        flex: inherit !important;
        position: relative;
        border-radius: 8px;
        background-color: var(--secondaryClr1);
        @media only screen and (max-width: 767.98px) {
          width: calc(32.7% - 0.5rem);
        }
        a {
          border-radius: 8px;
          font-weight: 300;
          transition: 0.4s;
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            border-radius: 8px;
            &.play_button {
              display: none;
            }
          }
          p {
            font-size: 0.875rem;
            width: 90%;
            color: rgb(226 232 240 / 0.5);
            text-align: center;
            height: 34px;
            display: none;
            justify-content: center;
            align-items: center;
            margin: 0;
            &.hover_Name {
              font-size: 14px !important;
              font-weight: 900;
              width: 100%;
              display: none;
              position: absolute;
              top: 50%;
              color: var(--whiteClr);
            }
          }
          &:hover {
            img {
              -webkit-filter: blur(4px); /* Chrome, Safari, Opera */
              filter: blur(4px);
              &.play_button {
                filter: none;
                display: none;
                position: absolute;
                top: 20%;
                width: 20%;
                height: auto;
                left: 40%;
              }
            }
            .hover_Name {
              display: block;
            }
          }
        }
      }
    }
  }
  .all-images li a ~ .focus-border::after,
  .all-images li a ~ .focus-border::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #f8fcff;
    transition: 0.3s;
    display: none;
  }
  .all-images li a ~ .focus-border::after {
    top: auto;
    bottom: 0;
    left: auto;
    right: 0;
    display: none;
  }
  .all-images li a ~ .focus-border i::after,
  .all-images li a ~ .focus-border i::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 0;
    background-color: #f8fcff;
    transition: 0.4s;
    display: none;
  }
  .all-images li a ~ .focus-border i::after {
    left: auto;
    right: 16px;
    top: auto;
    bottom: 11px;
    display: none;
  }
  .all-images li a:hover ~ .focus-border i::after,
  .all-images li a:hover ~ .focus-border i::before {
    height: 91%;
    transition: 0.4s;
    display: none;
  }
  .all-images li a:hover ~ .focus-border::after {
    width: 90% !important;
  }
  .all-images li a:hover ~ .focus-border i::before {
    top: 10px;
    left: 13px;
  }
  .all-images li a:hover ~ .focus-border::before {
    top: 10px;
    left: 14px;
  }
  .all-images li a:hover ~ .focus-border::after {
    bottom: 10px;
    right: 16px;
  }
  .all-images li a:hover ~ .focus-border::after,
  .all-images li a:hover ~ .focus-border::before {
    width: 90%;
    transition: 0.3s;
  }
}

.scrolltoTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  button {
    background-color: var(--secondaryClr1);
    border-radius: 0.5rem;
    border: 0;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    img {
      width: 30px;
    }
  }
}

.WhoweareSection {
  h3 {
    text-align: center;
    padding: 80px 0;
    background: var(--whiteClr);
    font-size: 55px;
    color: var(--blackClr);
    font-weight: 800;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    &::before {
      content: "";
      content: "";
      position: absolute;
      width: 115px;
      height: 4px;
      background-color: var(--blackClr);
      bottom: 150px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .Whowearepart {
    background: var(--gradientClr);
    padding: 30px 20px;
    .Whowearecontent {
      width: 70%;
      text-align: center;
      margin: 0 auto;
      display: block;
      p {
        font-size: 25px;
        color: var(--whiteClr);
        margin: 0 auto;
        text-align: center;
        line-height: 28px;
        font-weight: 300;
      }
    }
    .WhowearecontentSection {
      background: rgb(134, 86, 248);
      background: var(--secondaryClr1);
      margin: 20px auto 0;
      width: 70%;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;
        padding: 20px 35px;
        li {
          img {
            width: 34px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            font-weight: 800;
            margin: 0;
            letter-spacing: 0.5px;
          }
          h4 {
            font-size: 38px;
            font-weight: 800;
            letter-spacing: 1px;
            margin: 0;
          }
        }
      }
    }
  }
}
.ProblemSection {
  display: flex;
  margin: 2px 0;
  .ProblemLeftSection {
    background-color: var(--whiteClr);
    display: flex;
    align-items: center;
    justify-content: start;
    width: 55%;
    padding: 20px 0;
    img {
      width: 400px;
    }
  }
  .ProblemRightSection {
    background: rgb(255, 52, 50);
    background: var(--gradientClr);
    padding: 50px 30px;
    width: 45% !important;
    h3 {
      text-align: left;
      font-size: 55px;
      color: var(--whiteClr);
      font-weight: 800;
      position: relative;
      text-transform: uppercase;
      margin: 0;
      &::before {
        content: "";
        position: absolute;
        width: 100px;
        height: 4px;
        background-color: var(--whiteClr);
        bottom: 135px;
        left: 0px;
      }
    }
    .problempart {
      background: rgb(134, 86, 248);
      background: var(--secondaryClr1);
      margin: 45px auto 0;
      margin-left: -230px;
      ul {
        display: flex;
        justify-content: space-between;
        gap: 40px;
        padding: 25px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 90%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: var(--whiteClr);
        }
        li {
          flex: 1;
          img {
            width: 34px;
            margin-bottom: 10px;
          }
          h4 {
            margin: 0;
            font-size: 20px;
            font-weight: 700;
          }
          p {
            margin: 0;
            font-size: 16px;
            line-height: 18px;
            padding-top: 12px;
          }
        }
      }
    }
  }
}

.HowDoesItWorkSection {
  background-color: var(--whiteClr);
  text-align: center;
  h3 {
    font-size: 55px;
    color: var(--blackClr);
    font-weight: 800;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    padding: 80px 0 180px;
    &::before {
      content: "";
      position: absolute;
      width: 115px;
      height: 4px;
      background-color: var(--blackClr);
      bottom: 150px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .howdoesitworkpart {
    background: rgb(255, 52, 50);
    background: var(--gradientClr);
    padding: 0 30px 80px;
    ul {
      display: flex;
      justify-content: space-between;
      li {
        flex: 1;
        img {
          width: 280px;
          margin-top: -90px;
        }
        h4 {
          padding: 20px 0;
          font-size: 20px;
          font-weight: 700;
        }
        p {
          font-size: 20px;
          width: 70%;
          margin: 0 auto;
          line-height: 23px;
        }
      }
    }
  }
}

.StaticsSection {
  background-color: var(--whiteClr);
  padding: 50px 0 0;
  display: flex;
  align-items: center;
  .StaticsLeftSection {
    width: 50%;
    padding: 0 60px;
    position: relative;
    h3 {
      font-size: 55px;
      color: var(--blackClr);
      font-weight: 800;
      position: relative;
      text-transform: uppercase;
      margin: 0;
      &::before {
        content: "";
        position: absolute;
        width: 115px;
        height: 4px;
        background-color: var(--blackClr);
        bottom: 140px;
        left: 58px;
        transform: translateX(-50%);
      }
    }
    p {
      color: var(--blackClr);
      padding-top: 30px;
    }
  }

  .StaticsImg {
    width: 50%;
    display: flex;
    justify-content: center;
    img {
      width: 500px;
    }
  }
}
.AffiliatesNumber {
  background: rgb(255, 52, 50);
  background: var(--gradientClr);
  padding: 30px;
  ul {
    display: flex;
    gap: 25px 35px;
    li {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      span {
        font-weight: 700;
        font-size: 40px;
        text-align: center;
        line-height: 40px;
      }
      p {
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        margin: 0;
      }
    }
  }
}
.AffiliatesFooter {
  background-color: var(--whiteClr);
  .ContactHeading {
    padding: 50px 0;
    display: flex;
    justify-content: center;
    img {
      width: 400px;
    }
  }

  .bharatplayContact {
    background: rgb(255, 52, 50);
    background: var(--gradientClr);
    padding: 10px 0 100px;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: -13px;
          left: 50%;
          transform: translateX(-50%);
          width: 65px;
          height: 5px;
        }
        &:first-child {
          &::before {
            background-color: #05ff00;
          }
        }
        &:nth-child(2) {
          &::before {
            background-color: #69acd4;
          }
        }
        &:nth-child(3) {
          &::before {
            background-color: #ff6f6f;
          }
        }
        &:last-child {
          &::before {
            background-color: #a3e389;
          }
        }
        h4 {
          font-size: 22px;
          font-weight: 800;
        }
        p {
          margin: 0px;
        }
        a {
          color: var(--whiteClr);
          margin: 0;
        }
      }
    }
  }
}

.mainBanner {
  padding: 0 1rem;
  .slick-slide {
    img {
      width: 100%;
      height: auto;
      max-height: 400px;
      object-fit: cover;
      border-radius: 20px;
      padding-inline: 6px;
    }
    .content {
      bottom: auto;
      font-size: 0.85rem;
      left: 0;
      position: absolute;
      right: auto;
      text-align: left;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      z-index: 99;
      h2 {
        @media screen and (min-width: 768px) {
          font-size: 2rem;
        }
        @media screen and (min-width: 1024px) {
          font-size: 3.5rem;
        }
        @media screen and (max-width: 767px) {
          font-size: 1.15rem;
        }
        span {
          color: var(--primaryClr);
          margin: 0 8px;
        }
      }
      p {
        @media screen and (max-width: 767px) {
          font-size: 0.7rem;
        }
      }
    }
  }
  .slick-dots {
    bottom: 10px;
    text-align: left;
    padding-left: 15px;
    li {
      margin: 0;
      button {
        &::before {
          color: var(--primaryClr);
          font-size: 13px;
        }
      }
    }
  }
}
.promotion {
  .top-banner-wrapper {
    // background: linear-gradient(
    //   111.21deg,
    //   rgb(210, 236, 39) -88.74%,
    //   rgb(50, 55, 56) 46.65%
    // );
    background: linear-gradient(
      111.21deg,
      rgb(52 51 201 / 60%) -88.74%,
      rgb(50, 55, 56) 46.65%
    );
    display: grid;
    grid:
      "title items"
      "button items" / auto 1fr;
    gap: 1rem;
    padding: 1rem 2.5rem;
    margin: 30px 0 15px;
    border-radius: 0.5rem;
    @media (max-width: 991.98px) {
      margin: 15px 0;
    }
    @media (max-width: 991.98px) {
      grid:
        "title"
        "items"
        "button";
      padding: 1rem;
    }
    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      grid-area: title;
      .dpst-bonus {
        color: var(--whiteClr);
        font-size: 1.25rem;
        font-weight: 600;
        text-transform: capitalize;
        line-height: 1.25;
        max-width: 250px;
        @media (max-width: 575.98px) {
          font-size: 16px;
          max-width: 200px;
        }
      }
      .upto-text {
        color: var(--whiteClr);
        font-size: 2.25rem;
        font-weight: 800;
        line-height: 2.5rem;
        margin-top: 0.25rem;
        @media (max-width: 575.98px) {
          font-size: 20px;
          line-height: 1.75rem;
        }
      }
    }
    .dpst-btn {
      display: flex;
      column-gap: 0.5rem;
      grid-area: button;
      .button-brand {
        // background-image: linear-gradient(90deg, #24ee89, #9fe871);
        background: var(--gradientClr);
        // color: #000;
        color: var(--whiteClr);
        font-weight: 800;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        height: 2.5rem;
        border: 1px solid var(--primaryClr);
        border-radius: 0.5rem;
        // box-shadow: 0 0 12px #23ee884d, 0 -2px #1dca6a inset;
        box-shadow: rgba(14, 14, 52, 0.51) 0px 0px 12px,
          rgba(51, 50, 197, 0.49) 0px -2px inset;
      }
    }
    .banners {
      grid-area: items;
      @media (min-width: 992px) {
        display: flex;
        justify-content: flex-end;
      }
      .inner-wrapper {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(4, minmax(0px, 1fr));
        @media (max-width: 991.98px) {
          grid-template-columns: repeat(2, minmax(0px, 1fr));
        }
        .banner-box {
          display: flex;
          flex-direction: column;
          position: relative;
          background-size: 100% 100%;
          @media (min-width: 992px) {
            padding: 0 1rem 1rem;
          }
          &.box-1 {
            background-image: url("./assets/images/promotion/bonus-banner-1.webp");
            @media (max-width: 991.98px) {
              background-image: url("./assets/images/promotion/bonus-banner-mob-1.webp");
            }
          }
          &.box-2 {
            background-image: url("./assets/images/promotion/bonus-banner-2.webp");
            @media (max-width: 991.98px) {
              background-image: url("./assets/images/promotion/bonus-banner-mob-2.webp");
            }
          }
          &.box-3 {
            background-image: url("./assets/images/promotion/bonus-banner-3.webp");
            @media (max-width: 991.98px) {
              background-image: url("./assets/images/promotion/bonus-banner-mob-3.webp");
            }
          }
          &.box-4 {
            background-image: url("./assets/images/promotion/bonus-banner-4.webp");
            @media (max-width: 991.98px) {
              background-image: url("./assets/images/promotion/bonus-banner-mob-4.webp");
            }
          }
          .dadge-icon {
            position: absolute;
            top: 0.5rem;
            left: 0.5rem;
            width: 40px;
            height: 40px;
            @media (max-width: 991.98px) {
              top: -0.5rem;
              left: -0.5rem;
              width: 32px;
              height: 32px;
            }
            @media (max-width: 575.98px) {
              width: 22px;
              height: 22px;
            }
            .dadge-number {
              position: absolute;
              inset: 0;
              font-size: 0.875rem;
              line-height: 1.25rem;
              display: flex;
              justify-content: center;
              align-items: center;
              @media (max-width: 575.98px) {
                font-size: 10px;
              }
            }
          }
          .inner-content {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @media (min-width: 992px) {
              flex-direction: column;
              margin-top: 1.25rem;
            }
            .img-box {
              display: flex;
              flex-shrink: 1;
              max-width: 128px;
              @media (max-width: 991.98px) {
                order: 1;
                max-width: 40%;
              }
            }
            .text-box {
              @media (max-width: 991.98px) {
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                order: 0;
              }
              .bonus-text {
                color: var(--whiteClr);
                font-size: 1.35rem;
                font-weight: 800;
                text-transform: uppercase;
                text-align: center;
                line-height: 1.75rem;
                @media (max-width: 575.98px) {
                  font-size: 12px;
                  line-height: 1.25rem;
                }
              }
              .dpst-text {
                background: #323738;
                color: #b3bec1;
                font-size: 1rem;
                font-weight: 600;
                line-height: 1.25rem;
                display: flex;
                justify-content: center;
                align-items: center;
                width: max-content;
                padding: 0.25rem 0.5rem;
                border-radius: 0.375rem;
                @media (max-width: 575.98px) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
  .games-banner-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
    .banner-box {
      width: 33.33%;
      padding: 0 0.5rem;
      margin-bottom: 1rem;
      position: relative;
      @media (max-width: 1199.98px) and (min-width: 768px) {
        width: 50%;
      }
      @media (max-width: 767.98px) {
        width: 100%;
      }
      .banner-content {
        background-color: #323738;
        display: block;
        width: 100%;
        overflow: hidden;
        border-radius: 0.75rem;
        position: relative;
        &::after {
          --tw-content: "";
          content: var(--tw-content);
          display: block;
          padding-top: 43%;
        }
        &.banner-1 {
          background-image: linear-gradient(
            240deg,
            rgba(255, 168, 37, 0.4) -16.41%,
            rgba(255, 220, 37, 0) 71.96%
          );
        }
        &.banner-2 {
          background-image: linear-gradient(
            240deg,
            rgba(9, 9, 33, 0.9) -16.41%,
            rgba(36, 220, 114, 0) 71.96%
          );
        }
        .inner-wrapper {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: auto;
          transform-origin: left top;
          padding: 0 20px;
          @media (max-width: 575.98px) {
            padding: 0 15px;
          }
          .text-box {
            .brand {
              color: #24ee89;
              font-size: 24px;
              font-weight: 800;
              @media (max-width: 575.98px) {
                font-size: 22px;
              }
            }
            .primary {
              color: var(--whiteClr);
              font-size: 20px;
              font-weight: 800;
              @media (max-width: 575.98px) {
                font-size: 18px;
              }
            }
          }
          .img-box {
            max-width: 160px;
            border-radius: 0.375rem;
            @media (max-width: 1499.98px) and (min-width: 1350px) {
              max-width: 130px;
            }
            @media (max-width: 1349.98px) and (min-width: 1200px) {
              max-width: 100px;
            }
            @media (max-width: 1349.98px) and (min-width: 1200px) {
              max-width: 100px;
            }
            @media (max-width: 991.98px) and (min-width: 768px) {
              max-width: 120px;
            }
            @media (max-width: 575.98px) and (min-width: 360px) {
              max-width: 130px;
            }
            @media (max-width: 359.98px) {
              max-width: 110px;
            }
          }
        }
        &.banner-others {
          .inner-wrapper {
            padding: 0;
          }
        }
      }
      .bottom-content {
        background-color: rgb(41, 45, 46, 1);
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        padding: 0.75rem;
        border-radius: 0 0 0.5rem 0.5rem;
        .primary {
          color: var(--whiteClr);
          font-size: 1.05rem;
          font-weight: 800;
          line-height: 1.5rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .secondary {
          color: #b3bec1;
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1.25rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
